import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const images = [
    require('./../../images/products/Conference Table-2/2.jpg'),
    require('./../../images/products/Conference Table-2/3.jpg'),
    require('./../../images/products/Conference Table-2/4.jpg'),
    require('./../../images/products/Conference Table-2/1.jpg'),
    // require('./../../images/products/executive-desk-1/3.webp'),
    // require('./../../images/products/executive-desk-1/4.webp')
]

class About2 extends React.Component {
    
    render() {
        const options = {
            loop:true,
            autoplay:true,
            autoplayTimeout:3000,
            margin:30,
            nav:true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            items:1,
            dots: false,
        };
        return (
            <>
                <div className="section-full p-tb90 bg-gray square_shape2 tm-about2-wrap">
                        <div className="container">
                            <div className="section-content ">
                                <div className="row">
                                    <div className="col-lg-5 col-md-6">
                                        <div className="m-about m-l50 m-r50">
                                        <OwlCarousel className="owl-carousel about-us-carousel owl-btn-bottom-right" {...options}>
                                            {images.map((item, index) => (
                                                <div className="item" key={index}>
                                                <div className="ow-img wt-img-effect zoom-slow">
                                                <NavLink to={""}><img src={item.default} alt="" /></NavLink>
                                                    </div>
                                                </div>

                                            ))}

                                        </OwlCarousel>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 ">
                                        <div className="m-about-containt text-uppercase text-black p-t30">
                                            <span className="font-30 font-weight-300">About Us</span>
                                            <h2 className="font-40">Our mission is the best Development &amp; interior design.</h2>
                                            <p className='paragraph-text'>Hariom group was founded by <b>Mr. Chandrakant Vishwakarma</b> in 1990 about 33 years ago, by
                                                venturing into home furniture during its inception and later pioneered into manufacturing office
                                                modular furniture in India</p>
                                            <p  className='paragraph-text'>We cater to both residential as well as commercial clients while also undertaking turnkey projects
                                                since 1990 A.D. Our expertise includes wide array of dedicated services for interior of Residence,
                                                Commercial, Corporate, Hospitality (Such as: Hospitals, hotel and restaurants) & Institutional
                                                spaces.
                                            </p>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </>
        );
    }
};

export default About2;