import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const filters = [
    { label: "All Products", filter: ".all-products-col" },
    { label: "Linear Workstation", filter: ".linear-col" },
    { label: "Square MS Linear Workstation", filter: ".square-ms-linear-col" },
    { label: "Triangle MS Linear Workstation", filter: ".triangle-ms-linear-col" },
    { label: "Square MS Cubicle Workstation", filter: ".square-ms-cubicle-col" },
    { label: "Triangle MS Cubicle Workstation", filter: ".triangle-ms-cubicle-col" },
    { label: "Executive Square MS Pipe Workstation", filter: ".executive-square-ms-pipe-col" },
    { label: "Executive Triangle MS Pipe Workstation", filter: ".executive-triangle-ms-pipe-col" },
    { label: "Tapered Triangle Leg Workstation", filter: ".tapered-triangle-col" },
    { label: "MD Desk", filter: ".md-desk-col" },
    { label: "Executive Desk", filter: ".executive-desk-col" },
    { label: "Conference Table", filter: ".conference-table-col" },
    { label: "MS Leg Option", filter: ".ms-leg-option-col" },
];

const products = [
    {
        id: 'product_45',
        image: require('../../images/products/all-products/1.webp'),
        title: 'All Products',
        description: 'Executive Workstation with MS Leg',
        filter: 'all-products-col',
        folderName: 'all-products',
    },
    {
        id: 'product_45',
        image: require('../../images/products/all-products/2.webp'),
        title: 'All Products',
        description: 'Executive Workstation with P.B. Vertical',
        filter: 'all-products-col',
        folderName: 'all-products',
    },
    {
        id: 'product_45',
        image: require('../../images/products/all-products/3.webp'),
        title: 'All Products',
        description: 'Linear Workstation with MS Leg',
        filter: 'all-products-col',
        folderName: 'all-products',
    },
    {
        id: 'product_45',
        image: require('../../images/products/all-products/4.webp'),
        title: 'All Products',
        description: 'Linear Workstation with P.B. Vertical',
        filter: 'all-products-col',
        folderName: 'all-products',
    },
    {
        id: 'product_45',
        image: require('../../images/products/all-products/5.webp'),
        title: 'All Products',
        description: 'Linear Workstation with Tile Based Partition',
        filter: 'all-products-col',
        folderName: 'all-products',
    },
    {
        id: 'product_45',
        image: require('../../images/products/all-products/6.webp'),
        title: 'All Products',
        description: 'Executive Workstation with P.B. Vertical',
        filter: 'all-products-col',
        folderName: 'all-products',
    },
    {
        id: 'product_45',
        image: require('../../images/products/all-products/7.webp'),
        title: 'All Products',
        description: 'Storage.',
        filter: 'all-products-col',
        folderName: 'all-products',
    },
    {
        id: 'product_1',
        image: require('../../images/products/linear-workstation/1.webp'),
        title: 'Linear Workstation',
        description: 'All size available.',
        filter: 'linear-col',
        folderName: 'linear-workstation',
    },
    {
        id: 'product_1',
        image: require('../../images/products/linear-workstation/2.webp'),
        title: 'Linear Workstation',
        description: 'All size available.',
        filter: 'linear-col',
        folderName: 'linear-workstation',
    },
    {
        id: 'product_1',
        image: require('../../images/products/linear-workstation/3.webp'),
        title: 'Linear Workstation',
        description: 'All size available.',
        filter: 'linear-col',
        folderName: 'linear-workstation',
    },
    {
        id: 'product_1',
        image: require('../../images/products/linear-workstation/4.webp'),
        title: 'Linear Workstation',
        description: 'All size available.',
        filter: 'linear-col',
        folderName: 'linear-workstation',
    },
    {
        id: 'product_1',
        image: require('../../images/products/linear-workstation/5.webp'),
        title: 'Linear Workstation',
        description: 'All size available.',
        filter: 'linear-col',
        folderName: 'linear-workstation',
    },
    {
        id: 'product_1',
        image: require('../../images/products/linear-workstation/6.webp'),
        title: 'Linear Workstation',
        description: 'All size available.',
        filter: 'linear-col',
        folderName: 'linear-workstation',
    },
    {
        id: 'product_1',
        image: require('../../images/products/linear-workstation/7.webp'),
        title: 'Linear Workstation',
        description: 'All size available.',
        filter: 'linear-col',
        folderName: 'linear-workstation',
    },
    {
        id: 'product_1',
        image: require('../../images/products/linear-workstation/8.webp'),
        title: 'Linear Workstation',
        description: 'All size available.',
        filter: 'linear-col',
        folderName: 'linear-workstation',
    },
    {
        id: 'product_1',
        image: require('../../images/products/linear-workstation/9.webp'),
        title: 'Linear Workstation',
        description: 'All size available.',
        filter: 'linear-col',
        folderName: 'linear-workstation',
    },
    {
        id: 'product_1',
        image: require('../../images/products/linear-workstation/10.webp'),
        title: 'Linear Workstation',
        description: 'All size available.',
        filter: 'linear-col',
        folderName: 'linear-workstation',
    },
    {
        id: 'product_1',
        image: require('../../images/products/linear-workstation/11.webp'),
        title: 'Linear Workstation',
        description: 'All size available.',
        filter: 'linear-col',
        folderName: 'linear-workstation',
    },
    {
        id: 'product_2',
        image: require('../../images/products/square-ms-linear-workstation/1.webp'),
        title: 'Square MS Linear Workstation',
        description: 'All size available.',
        filter: 'square-ms-linear-col',
        folderName: 'square-ms-linear-workstation',
    },
    {
        id: 'product_2',
        image: require('../../images/products/square-ms-linear-workstation/2.webp'),
        title: 'Square MS Linear Workstation',
        description: 'All size available.',
        filter: 'square-ms-linear-col',
        folderName: 'square-ms-linear-workstation',
    },
    {
        id: 'product_2',
        image: require('../../images/products/square-ms-linear-workstation/3.webp'),
        title: 'Square MS Linear Workstation',
        description: 'All size available.',
        filter: 'square-ms-linear-col',
        folderName: 'square-ms-linear-workstation',
    },
    {
        id: 'product_2',
        image: require('../../images/products/square-ms-linear-workstation/4.webp'),
        title: 'Square MS Linear Workstation',
        description: 'All size available.',
        filter: 'square-ms-linear-col',
        folderName: 'square-ms-linear-workstation',
    },
    {
        id: 'product_3',
        image: require('../../images/products/triangle-ms-linear-workstation/1.webp'),
        title: 'Triangle MS Linear Workstation',
        description: 'All size available.',
        filter: 'triangle-ms-linear-col',
        folderName: 'triangle-ms-linear-workstation',
    },
    {
        id: 'product_3',
        image: require('../../images/products/triangle-ms-linear-workstation/2.webp'),
        title: 'Triangle MS Linear Workstation',
        description: 'All size available.',
        filter: 'triangle-ms-linear-col',
        folderName: 'triangle-ms-linear-workstation',
    },
    {
        id: 'product_3',
        image: require('../../images/products/triangle-ms-linear-workstation/3.webp'),
        title: 'Triangle MS Linear Workstation',
        description: 'All size available.',
        filter: 'triangle-ms-linear-col',
        folderName: 'triangle-ms-linear-workstation',
    },
    {
        id: 'product_3',
        image: require('../../images/products/triangle-ms-linear-workstation/4.webp'),
        title: 'Triangle MS Linear Workstation',
        description: 'All size available.',
        filter: 'triangle-ms-linear-col',
        folderName: 'triangle-ms-linear-workstation',
    },
    {
        id: 'product_3',
        image: require('../../images/products/triangle-ms-linear-workstation/5.webp'),
        title: 'Triangle MS Linear Workstation',
        description: 'All size available.',
        filter: 'triangle-ms-linear-col',
        folderName: 'triangle-ms-linear-workstation',
    },
    {
        id: 'product_3',
        image: require('../../images/products/triangle-ms-linear-workstation/6.webp'),
        title: 'Triangle MS Linear Workstation',
        description: 'All size available.',
        filter: 'triangle-ms-linear-col',
        folderName: 'triangle-ms-linear-workstation',
    },
    {
        id: 'product_3',
        image: require('../../images/products/triangle-ms-linear-workstation/7.webp'),
        title: 'Triangle MS Linear Workstation',
        description: 'All size available.',
        filter: 'triangle-ms-linear-col',
        folderName: 'triangle-ms-linear-workstation',
    },
    {
        id: 'product_4',
        image: require('../../images/products/square-ms-cubicle-workstation/1.webp'),
        title: 'Square MS Cubicle Workstation',
        description: 'All size available.',
        filter: 'square-ms-cubicle-col',
        folderName: 'square-ms-cubicle-workstation',
    },
    {
        id: 'product_4',
        image: require('../../images/products/square-ms-cubicle-workstation/2.webp'),
        title: 'Square MS Cubicle Workstation',
        description: 'All size available.',
        filter: 'square-ms-cubicle-col',
        folderName: 'square-ms-cubicle-workstation',
    },
    {
        id: 'product_4',
        image: require('../../images/products/square-ms-cubicle-workstation/3.webp'),
        title: 'Square MS Cubicle Workstation',
        description: 'All size available.',
        filter: 'square-ms-cubicle-col',
        folderName: 'square-ms-cubicle-workstation',
    },
    {
        id: 'product_4',
        image: require('../../images/products/square-ms-cubicle-workstation/4.webp'),
        title: 'Square MS Cubicle Workstation',
        description: 'All size available.',
        filter: 'square-ms-cubicle-col',
        folderName: 'square-ms-cubicle-workstation',
    },
    {
        id: 'product_4',
        image: require('../../images/products/square-ms-cubicle-workstation/5.webp'),
        title: 'Square MS Cubicle Workstation',
        description: 'All size available.',
        filter: 'square-ms-cubicle-col',
        folderName: 'square-ms-cubicle-workstation',
    },
    {
        id: 'product_5',
        image: require('../../images/products/triangle-ms-cubicle-workstation/1.webp'),
        title: 'Triangle MS Cubicle Workstation',
        description: 'All size available.',
        filter: 'triangle-ms-cubicle-col',
        folderName: 'triangle-ms-cubicle-workstation',
    },
    {
        id: 'product_5',
        image: require('../../images/products/triangle-ms-cubicle-workstation/2.webp'),
        title: 'Triangle MS Cubicle Workstation',
        description: 'All size available.',
        filter: 'triangle-ms-cubicle-col',
        folderName: 'triangle-ms-cubicle-workstation',
    },
    {
        id: 'product_5',
        image: require('../../images/products/triangle-ms-cubicle-workstation/3.webp'),
        title: 'Triangle MS Cubicle Workstation',
        description: 'All size available.',
        filter: 'triangle-ms-cubicle-col',
        folderName: 'triangle-ms-cubicle-workstation',
    },
    {
        id: 'product_5',
        image: require('../../images/products/triangle-ms-cubicle-workstation/4.webp'),
        title: 'Triangle MS Cubicle Workstation',
        description: 'All size available.',
        filter: 'triangle-ms-cubicle-col',
        folderName: 'triangle-ms-cubicle-workstation',
    },
    {
        id: 'product_5',
        image: require('../../images/products/triangle-ms-cubicle-workstation/5.webp'),
        title: 'Triangle MS Cubicle Workstation',
        description: 'All size available.',
        filter: 'triangle-ms-cubicle-col',
        folderName: 'triangle-ms-cubicle-workstation',
    },
    {
        id: 'product_6',
        image: require('../../images/products/executive-square-ms-pipe-workstation/1.webp'),
        title: 'Executive Square MS Pipe Workstation',
        description: 'All size available.',
        filter: 'executive-square-ms-pipe-col',
        folderName: 'executive-square-ms-pipe-workstation',
    },
    {
        id: 'product_6',
        image: require('../../images/products/executive-square-ms-pipe-workstation/2.webp'),
        title: 'Executive Square MS Pipe Workstation',
        description: 'All size available.',
        filter: 'executive-square-ms-pipe-col',
        folderName: 'executive-square-ms-pipe-workstation',
    },
    {
        id: 'product_6',
        image: require('../../images/products/executive-square-ms-pipe-workstation/3.webp'),
        title: 'Executive Square MS Pipe Workstation',
        description: 'All size available.',
        filter: 'executive-square-ms-pipe-col',
        folderName: 'executive-square-ms-pipe-workstation',
    },
    {
        id: 'product_6',
        image: require('../../images/products/executive-square-ms-pipe-workstation/4.webp'),
        title: 'Executive Square MS Pipe Workstation',
        description: 'All size available.',
        filter: 'executive-square-ms-pipe-col',
        folderName: 'executive-square-ms-pipe-workstation',
    },
    {
        id: 'product_6',
        image: require('../../images/products/executive-square-ms-pipe-workstation/5.webp'),
        title: 'Executive Square MS Pipe Workstation',
        description: 'All size available.',
        filter: 'executive-square-ms-pipe-col',
        folderName: 'executive-square-ms-pipe-workstation',
    },
    {
        id: 'product_6',
        image: require('../../images/products/executive-square-ms-pipe-workstation/6.webp'),
        title: 'Executive Square MS Pipe Workstation',
        description: 'All size available.',
        filter: 'executive-square-ms-pipe-col',
        folderName: 'executive-square-ms-pipe-workstation',
    },
    {
        id: 'product_6',
        image: require('../../images/products/executive-square-ms-pipe-workstation/7.webp'),
        title: 'Executive Square MS Pipe Workstation',
        description: 'All size available.',
        filter: 'executive-square-ms-pipe-col',
        folderName: 'executive-square-ms-pipe-workstation',
    },
    {
        id: 'product_6',
        image: require('../../images/products/executive-square-ms-pipe-workstation/8.webp'),
        title: 'Executive Square MS Pipe Workstation',
        description: 'All size available.',
        filter: 'executive-square-ms-pipe-col',
        folderName: 'executive-square-ms-pipe-workstation',
    },
    {
        id: 'product_6',
        image: require('../../images/products/executive-square-ms-pipe-workstation/9.webp'),
        title: 'Executive Square MS Pipe Workstation',
        description: 'All size available.',
        filter: 'executive-square-ms-pipe-col',
        folderName: 'executive-square-ms-pipe-workstation',
    },
    {
        id: 'product_6',
        image: require('../../images/products/executive-square-ms-pipe-workstation/10.webp'),
        title: 'Executive Square MS Pipe Workstation',
        description: 'All size available.',
        filter: 'executive-square-ms-pipe-col',
        folderName: 'executive-square-ms-pipe-workstation',
    },
    {
        id: 'product_7',
        image: require('../../images/products/executive-tirangle-ms-pipe-workstation/1.webp'),
        title: 'Executive Triangle MS Pipe Workstation',
        description: 'All size available.',
        filter: 'executive-triangle-ms-pipe-col',
        folderName: 'executive-triangle-ms-pipe-workstation',
    },
    {
        id: 'product_7',
        image: require('../../images/products/executive-tirangle-ms-pipe-workstation/2.webp'),
        title: 'Executive Triangle MS Pipe Workstation',
        description: 'All size available.',
        filter: 'executive-triangle-ms-pipe-col',
        folderName: 'executive-triangle-ms-pipe-workstation',
    },
    {
        id: 'product_7',
        image: require('../../images/products/executive-tirangle-ms-pipe-workstation/3.webp'),
        title: 'Executive Triangle MS Pipe Workstation',
        description: 'All size available.',
        filter: 'executive-triangle-ms-pipe-col',
        folderName: 'executive-triangle-ms-pipe-workstation',
    },
    {
        id: 'product_7',
        image: require('../../images/products/executive-tirangle-ms-pipe-workstation/4.webp'),
        title: 'Executive Triangle MS Pipe Workstation',
        description: 'All size available.',
        filter: 'executive-triangle-ms-pipe-col',
        folderName: 'executive-triangle-ms-pipe-workstation',
    },
    {
        id: 'product_7',
        image: require('../../images/products/executive-tirangle-ms-pipe-workstation/5.webp'),
        title: 'Executive Triangle MS Pipe Workstation',
        description: 'All size available.',
        filter: 'executive-triangle-ms-pipe-col',
        folderName: 'executive-triangle-ms-pipe-workstation',
    },
    {
        id: 'product_7',
        image: require('../../images/products/executive-tirangle-ms-pipe-workstation/6.webp'),
        title: 'Executive Triangle MS Pipe Workstation',
        description: 'All size available.',
        filter: 'executive-triangle-ms-pipe-col',
        folderName: 'executive-triangle-ms-pipe-workstation',
    },
    {
        id: 'product_8',
        image: require('../../images/products/tapered-triangle-leg-workstation/1.webp'),
        title: 'Tapered Triangle Leg Workstation',
        description: 'All size available.',
        filter: 'tapered-triangle-col',
        folderName: 'tapered-triangle-leg-workstation',
    },
    {
        id: 'product_8',
        image: require('../../images/products/tapered-triangle-leg-workstation/2.webp'),
        title: 'Tapered Triangle Leg Workstation',
        description: 'All size available.',
        filter: 'tapered-triangle-col',
        folderName: 'tapered-triangle-leg-workstation',
    },
    {
        id: 'product_8',
        image: require('../../images/products/tapered-triangle-leg-workstation/3.webp'),
        title: 'Tapered Triangle Leg Workstation',
        description: 'All size available.',
        filter: 'tapered-triangle-col',
        folderName: 'tapered-triangle-leg-workstation',
    },
    {
        id: 'product_8',
        image: require('../../images/products/tapered-triangle-leg-workstation/4.webp'),
        title: 'Tapered Triangle Leg Workstation',
        description: 'All size available.',
        filter: 'tapered-triangle-col',
        folderName: 'tapered-triangle-leg-workstation',
    },
    {
        id: 'product_8',
        image: require('../../images/products/tapered-triangle-leg-workstation/5.webp'),
        title: 'Tapered Triangle Leg Workstation',
        description: 'All size available.',
        filter: 'tapered-triangle-col',
        folderName: 'tapered-triangle-leg-workstation',
    },
    {
        id: 'product_9',
        image: require('../../images/products/md-desk/1.webp'),
        title: 'MD Desk',
        description: 'All size available.',
        filter: 'md-desk-col',
        folderName: 'md-desk',
    },
    {
        id: 'product_9',
        image: require('../../images/products/md-desk/2.webp'),
        title: 'MD Desk',
        description: 'All size available.',
        filter: 'md-desk-col',
        folderName: 'md-desk',
    },
    {
        id: 'product_9',
        image: require('../../images/products/md-desk/3.webp'),
        title: 'MD Desk',
        description: 'All size available.',
        filter: 'md-desk-col',
        folderName: 'md-desk',
    },
    {
        id: 'product_9',
        image: require('../../images/products/md-desk/4.webp'),
        title: 'MD Desk',
        description: 'All size available.',
        filter: 'md-desk-col',
        folderName: 'md-desk',
    },
    {
        id: 'product_9',
        image: require('../../images/products/md-desk/5.webp'),
        title: 'MD Desk',
        description: 'All size available.',
        filter: 'md-desk-col',
        folderName: 'md-desk',
    },
    {
        id: 'product_9',
        image: require('../../images/products/md-desk/6.webp'),
        title: 'MD Desk',
        description: 'All size available.',
        filter: 'md-desk-col',
        folderName: 'md-desk',
    },
    {
        id: 'product_9',
        image: require('../../images/products/md-desk/7.webp'),
        title: 'MD Desk',
        description: 'All size available.',
        filter: 'md-desk-col',
        folderName: 'md-desk',
    },
    {
        id: 'product_9',
        image: require('../../images/products/md-desk/8.webp'),
        title: 'MD Desk',
        description: 'All size available.',
        filter: 'md-desk-col',
        folderName: 'md-desk',
    },
    {
        id: 'product_9',
        image: require('../../images/products/md-desk/9.webp'),
        title: 'MD Desk',
        description: 'All size available.',
        filter: 'md-desk-col',
        folderName: 'md-desk',
    },
    {
        id: 'product_9',
        image: require('../../images/products/md-desk/10.webp'),
        title: 'MD Desk',
        description: 'All size available.',
        filter: 'md-desk-col',
        folderName: 'md-desk',
    },
    {
        id: 'product_9',
        image: require('../../images/products/md-desk/11.webp'),
        title: 'MD Desk',
        description: 'All size available.',
        filter: 'md-desk-col',
        folderName: 'md-desk',
    },
    {
        id: 'product_9',
        image: require('../../images/products/md-desk/12.webp'),
        title: 'MD Desk',
        description: 'All size available.',
        filter: 'md-desk-col',
        folderName: 'md-desk',
    },
    {
        id: 'product_9',
        image: require('../../images/products/md-desk/13.webp'),
        title: 'MD Desk',
        description: 'All size available.',
        filter: 'md-desk-col',
        folderName: 'md-desk',
    },
    {
        id: 'product_9',
        image: require('../../images/products/md-desk/14.webp'),
        title: 'MD Desk',
        description: 'All size available.',
        filter: 'md-desk-col',
        folderName: 'md-desk',
    },
    {
        id: 'product_9',
        image: require('../../images/products/md-desk/15.webp'),
        title: 'MD Desk',
        description: 'All size available.',
        filter: 'md-desk-col',
        folderName: 'md-desk',
    },
    {
        id: 'product_10',
        image: require('../../images/products/executive-desk-1/1.webp'),
        title: 'Executive Desk',
        description: 'All size available.',
        filter: 'executive-desk-col',
        folderName: 'executive-desk-1',
    },
    {
        id: 'product_10',
        image: require('../../images/products/executive-desk-1/2.webp'),
        title: 'Executive Desk',
        description: 'All size available.',
        filter: 'executive-desk-col',
        folderName: 'executive-desk-1',
    },
    {
        id: 'product_10',
        image: require('../../images/products/executive-desk-1/3.webp'),
        title: 'Executive Desk',
        description: 'All size available.',
        filter: 'executive-desk-col',
        folderName: 'executive-desk-1',
    },
    {
        id: 'product_10',
        image: require('../../images/products/executive-desk-1/4.webp'),
        title: 'Executive Desk',
        description: 'All size available.',
        filter: 'executive-desk-col',
        folderName: 'executive-desk-1',
    },
    {
        id: 'product_10',
        image: require('../../images/products/executive-desk-1/5.webp'),
        title: 'Executive Desk',
        description: 'All size available.',
        filter: 'executive-desk-col',
        folderName: 'executive-desk-1',
    },
    {
        id: 'product_10',
        image: require('../../images/products/executive-desk-1/6.webp'),
        title: 'Executive Desk',
        description: 'All size available.',
        filter: 'executive-desk-col',
        folderName: 'executive-desk-1',
    },
    {
        id: 'product_10',
        image: require('../../images/products/executive-desk-1/7.webp'),
        title: 'Executive Desk',
        description: 'All size available.',
        filter: 'executive-desk-col',
        folderName: 'executive-desk-1',
    },
    {
        id: 'product_10',
        image: require('../../images/products/executive-desk-1/8.webp'),
        title: 'Executive Desk',
        description: 'All size available.',
        filter: 'executive-desk-col',
        folderName: 'executive-desk-1',
    },
    {
        id: 'product_10',
        image: require('../../images/products/executive-desk-1/9.webp'),
        title: 'Executive Desk',
        description: 'All size available.',
        filter: 'executive-desk-col',
        folderName: 'executive-desk-1',
    },
    {
        id: 'product_11',
        image: require('../../images/products/Conference Table-1/1.jpg'),
        title: 'Conference Table',
        description: 'All size available.',
        filter: 'conference-table-col',
        folderName: 'Conference Table-1',
    },
    {
        id: 'product_11',
        image: require('../../images/products/Conference Table-1/2.jpg'),
        title: 'Conference Table',
        description: 'All size available.',
        filter: 'conference-table-col',
        folderName: 'Conference Table-1',
    },
    {
        id: 'product_11',
        image: require('../../images/products/Conference Table-1/3.jpg'),
        title: 'Conference Table',
        description: 'All size available.',
        filter: 'conference-table-col',
        folderName: 'Conference Table-1',
    },
    {
        id: 'product_11',
        image: require('../../images/products/Conference Table-1/4.jpg'),
        title: 'Conference Table',
        description: 'All size available.',
        filter: 'conference-table-col',
        folderName: 'Conference Table-1',
    },
    {
        id: 'product_11',
        image: require('../../images/products/Conference Table-2/1.jpg'),
        title: 'Conference Table',
        description: 'All size available.',
        filter: 'conference-table-col',
        folderName: 'Conference Table-2',
    },
    {
        id: 'product_11',
        image: require('../../images/products/Conference Table-2/2.jpg'),
        title: 'Conference Table',
        description: 'All size available.',
        filter: 'conference-table-col',
        folderName: 'Conference Table-2',
    },
    {
        id: 'product_11',
        image: require('../../images/products/Conference Table-2/3.jpg'),
        title: 'Conference Table',
        description: 'All size available.',
        filter: 'conference-table-col',
        folderName: 'Conference Table-2',
    },
    {
        id: 'product_11',
        image: require('../../images/products/Conference Table-2/4.jpg'),
        title: 'Conference Table',
        description: 'All size available.',
        filter: 'conference-table-col',
        folderName: 'Conference Table-2',
    },
    {
        id: 'product_12',
        image: require('../../images/products/ms-leg-option/1.webp'),
        title: 'MS Leg Option',
        description: 'All size available.',
        filter: 'ms-leg-option-col',
        folderName: 'ms-leg-option',
    },
    {
        id: 'product_12',
        image: require('../../images/products/ms-leg-option/2.webp'),
        title: 'MS Leg Option',
        description: 'All size available.',
        filter: 'ms-leg-option-col',
        folderName: 'ms-leg-option',
    },
    {
        id: 'product_12',
        image: require('../../images/products/ms-leg-option/3.webp'),
        title: 'MS Leg Option',
        description: 'All size available.',
        filter: 'ms-leg-option-col',
        folderName: 'ms-leg-option',
    },
    {
        id: 'product_12',
        image: require('../../images/products/ms-leg-option/4.webp'),
        title: 'MS Leg Option',
        description: 'All size available.',
        filter: 'ms-leg-option-col',
        folderName: 'ms-leg-option',
    },
    {
        id: 'product_12',
        image: require('../../images/products/ms-leg-option/5.webp'),
        title: 'MS Leg Option',
        description: 'All size available.',
        filter: 'ms-leg-option-col',
        folderName: 'ms-leg-option',
    },
    {
        id: 'product_12',
        image: require('../../images/products/ms-leg-option/6.webp'),
        title: 'MS Leg Option',
        description: 'All size available.',
        filter: 'ms-leg-option-col',
        folderName: 'ms-leg-option',
    },
    {
        id: 'product_12',
        image: require('../../images/products/ms-leg-option/7.webp'),
        title: 'MS Leg Option',
        description: 'All size available.',
        filter: 'ms-leg-option-col',
        folderName: 'ms-leg-option',
    },
]

class LatestProducts extends React.Component {
    componentDidMount(){
        function loadScript(src) {
           
          return new Promise(function(resolve, reject){
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
              resolve();
            });
            script.addEventListener('error', function (e) {
              reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
          })
        };
 
      loadScript('./assets/js/custom.js');
      
    };
    render() {
        const options = {
            loop:false,
            autoplay:true,
            margin:20,
            nav:true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                480:{
                    items:1
                },
                580:{
                    items:2
                },						
                767:{
                    items:2
                },
                991:{
                    items:3
                },			
                1152:{
                    items:4
                },
                1360:{
                    items:4
                },
                1366:{
                    items:5
                }	
                }
        };
        return (
            <>
                <div className="section-full p-t60 p-lr80 latest_project-outer square_shape3">
                        {/* TITLE START */}
                        <div className="section-head text-left">
                            <div className="row">
                                <div className="col-lg-4 col-md-12">
                                    <h2 className="text-uppercase font-36">Our Products</h2>
                                    <div className="wt-separator-outer">
                                        <div className="wt-separator bg-black" />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <ul className="btn-filter-wrap">
                                        {/* <li className="btn-filter btn-active" data-filter="*">All Products</li> */}
                                        {filters.map((item, index) => (
                                            <li key={index} className= {index == 0 ? "btn-filter btn-active": "btn-filter" } data-filter={item.filter}>{item.label}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <OwlCarousel className="owl-carousel owl-carousel-filter  owl-btn-bottom-left" {...options}>
                                        {products.map((item, index) => {
                                            const imgName = item.image?.default?.split('/')[3];
                                            const searchParam = `search=${imgName}&folder=${item.folderName}&id=${item.id}&title=${item.title}`;
                                            return (
                                                <div key={index} className={`${item.filter} item fadingcol`}>
                                                    <div className="wt-img-effect ">
                                                        <img src={item.image.default} alt="" style={{height: '250px'}}/>
                                                        <div className="overlay-bx-2 ">
                                                            <div className="line-amiation">
                                                                <div className="text-white  font-weight-300 p-a40">
                                                                    <h2>
                                                                        <NavLink 
                                                                            to={{
                                                                                pathname: "/product-detail", 
                                                                                search: searchParam
                                                                            }} 
                                                                            className="text-white font-20 letter-spacing-1 text-uppercase">
                                                                                {item.title}
                                                                        </NavLink>
                                                                    </h2>
                                                                    <p>{item.description}</p>
                                                                    <NavLink 
                                                                        to={{
                                                                            pathname:`/product-detail`,
                                                                            search: searchParam,
                                                                        }} 
                                                                        className="v-button letter-spacing-4 font-12 text-uppercase p-l20">Read More</NavLink>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        )}
                                    </OwlCarousel>
                        </div>
                        <div className="hilite-title p-lr20 m-tb20 text-right text-uppercase bdr-gray bdr-right">
                            <strong>Awesome</strong>
                            <span className="text-black">Designs</span>
                        </div>
                    </div>
            </>
        );
    }
};

export default LatestProducts;