import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const members = [
    {
        image: require('./../../images/testimonials/img1.png'),
        fullname: 'Mr. Jagdeep Oberoi',
        position: '',
        description: 'Really great to deal with! quick response time with kind helpful people on the other end, and great service! the guys that come to instal the things are quick, mess free, hassle free, people really a pleasure to work with! thanks!:)',
    },
    {
        image: require('./../../images/testimonials/img1.png'),
        fullname: 'Mr. Mahesh Partwalkar',
        position: '',
        description: 'Overall experience was excellent a simple and straight forward process. I liked the updates which were sent through. The most important part was the quality of goods received very impressed indeed.',
    },
    {
        image: require('./../../images/testimonials/img1.png'),
        fullname: 'Mr. Dilip Poddar',
        position: '',
        description: 'Great Service, from ordering to delivery and installation.',
    },
    {
        image: require('./../../images/testimonials/img1.png'),
        fullname: 'Dr. Samarjit S Bansal',
        position: '',
        description: 'Placed an order for replacement office. Delivery + Installation Date agreed at time of booking, absolute pleasure to deal with, will be using again and would recommend to any one looking for great quality office furniture.',
    },
    {
        image: require('./../../images/testimonials/img1.png'),
        fullname: 'Dr. Bhavisha Bansal',
        position: '',
        description: 'Good quality and service. Everything arrived on time. Fitters were excellent and friendly. First class service.',
    },
    {
        image: require('./../../images/testimonials/img1.png'),
        fullname: 'Mr. Bodhisatva Datta',
        position: 'Director - Ink Bling Designs Pvt.',
        description: 'Perfect delivery and set up. great addition to our office.',
    }
]

var bgimg = require('./../../images/background/ptn-1.png');

class Testimonials extends React.Component {

    render() {
        const options = {
            loop: true,
            autoplay: false,
            margin: 80,
            nav: false,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1
                },
                991: {
                    items: 2
                }
            }
        };
        return (
            <>
                <div className="section-full p-t50 bg-repeat tm-testimonial-wrap" style={{ backgroundImage: 'url(' + bgimg.default + ')' }}>
                    <div className="container">
                        <div className="section-content">
                            {/* TITLE START */}
                            <div className="section-head text-left">
                                <h2 className="text-uppercase font-36">Testimonials</h2>
                                <div className="wt-separator-outer">
                                    <div className="wt-separator bg-black" />
                                </div>
                            </div>
                            {/* TITLE END */}
                            {/* TESTIMONIAL 4 START ON BACKGROUND */}
                            <div className="section-content">
                                <OwlCarousel className="owl-carousel testimonial-home" {...options}>
                                    {members.map((item, index) => (
                                        <div className="item" key={index}>
                                            <div className="testimonial-6">
                                                <div className="testimonial-pic-block">
                                                    <div className="testimonial-pic">
                                                        <img src={item.image.default} alt="" width={132} height={132} />
                                                    </div>
                                                </div>
                                                <div className="testimonial-text clearfix bg-white">
                                                    <div className="testimonial-detail clearfix">
                                                        <strong className="testimonial-name text-color-red">{item.fullname}</strong>
                                                        <span className="testimonial-position p-t0">{item.position}</span>
                                                    </div>
                                                    <div className="testimonial-paragraph text-black p-t15">
                                                        <span className="fa fa-quote-left" />
                                                        <p>{item.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    ))}

                                </OwlCarousel>

                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="hilite-title p-lr20 m-tb20 text-right text-uppercase bdr-gray bdr-right">
                            <strong>Client</strong>
                            <span className="text-black">Says</span>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Testimonials;