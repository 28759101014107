import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../Common/Header';
import Footer from '../Common/Footer3';
import Banner from '../Segments/Banner';
import { products } from '../JsonData/productsData';

var bnrimg = require('./../../images/main-slider/slider1/006_result.webp');

class ProductsList extends React.Component {
    componentDidMount(){
        function loadScript(src) {
           
          return new Promise(function(resolve, reject){
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
              resolve();
            });
            script.addEventListener('error', function (e) {
              reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
          })
        };
 
      loadScript('./assets/js/custom.js');
      
    };
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Banner title="Exploring our clients goals, priorities, lifestyle, and tastes" pagename="Our Products" bgimage={bnrimg.default} />
                    {products.map((product, index) => {
                        return (
                            <>
                                {/* SECTION CONTENT START */}
                                <div className="section-full p-tb10 tm-work-wrap">
                                    <div className="container" style={{marginLeft: '10px'}}>
                                        <div className="filter-wrap p-b10">
                                            <div className="masonry-filter link-style text-uppercase">
                                                <h2>{product.title}</h2>
                                            </div>
                                            {/* <ul className="masonry-filter link-style text-uppercase">
                                                <li className="active">
                                                    <NavLink to={"#"} data-filter="*" data-hover="All">
                                                        {product.title}
                                                    </NavLink>
                                                </li>
                                            </ul> */}
                                        </div>
                                    </div>
                                    {/* GALLERY CONTENT START */}
                                    <div className="portfolio-wrap mfp-gallery news-grid clearfix"  style={{marginLeft: '10px'}}>
                                        <div className="container-fluid">
                                            <div className="row">
                                                {product.dataList.map((item) => {
                                                    const imgName = item.image?.default?.split('/')[3];
                                                    const searchParam = `search=${imgName}&folder=${item.folderName}&id=${product.id}&title=${product.title}`;
                                                    return (
                                                        <div key={index} className={`${item.filter} masonry-item col-lg-3 col-md-6 col-sm-6 m-b30`}>
                                                            <div className="wt-img-effect ">
                                                                <img style={{height: '300px'}} src={item.image.default} alt="" />
                                                                <div className="overlay-bx-2 ">
                                                                    <div className="line-amiation  p-a40">
                                                                        <div className="wt-post-info text-white">
                                                                            <div className="wt-post-title ">
                                                                                <h2 className="post-title">
                                                                                    <NavLink 
                                                                                        to={{
                                                                                            pathname:`/product-detail`,
                                                                                            search: searchParam,
                                                                                        }} 
                                                                                        className="text-white font-18 letter-spacing-4 font-weight-600">
                                                                                        {item.title}
                                                                                    </NavLink>
                                                                                </h2>
                                                                            </div>
                                                                            <div className="wt-post-text">
                                                                                <p>{item.description}</p>
                                                                            </div>
                                                                            <NavLink 
                                                                                to={{
                                                                                    pathname:`/product-detail`,
                                                                                    search: searchParam,
                                                                                }} 
                                                                                className="v-button letter-spacing-4 font-12 text-uppercase p-l20">
                                                                                Read More
                                                                            </NavLink>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    {/* GALLERY CONTENT END */}
                                </div>
                                {/* SECTION CONTENT END  */}
                            </>
                        )
                    })}
                    
                </div>

                <Footer />
            </>
        );
    };
};

export default ProductsList;