import React from 'react';
import Header from '../Common/Header';
import Footer3 from '../Common/Footer3';
import Banner from './../Segments/Banner';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { productsDescription } from '../JsonData/productsData';

var bnrimg = require('./../../images/banner/022_result.webp');
class ProductDetail extends React.Component {
    constructor(props) {
        super(props);
        const imgName = this.props?.location?.search?.split('&')[0]?.split('=')[1]??'notFound';
        const currentImgSrc = `/static/media/${imgName}`;
        const folderName = this.props?.location?.search?.split('&')[1]?.split('=')[1]?.replace("%20", " ")??null;
        const productId = this.props?.location?.search?.split('&')[2]?.split('=')[1]
        const productTitle = this.props?.location?.search?.split('&')[3]?.split('=')[1].replace("%20", " ")??null
        let imagesList = [];
        if(folderName !== null){
            const images = require.context('./../../images/products/', true);
            imagesList = images.keys().map(image => {
                    const folderMatch = image?.split('/')[1];
                    return folderMatch === folderName && images(image)
                }
            );
        }
        // Set initial state
        this.state = {
            currentSelectionImg: currentImgSrc,
            imagesList: imagesList,
            folderName: folderName,
            productId: productId,
            productTitle: productTitle
        };
        // Binding this keyword
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(test) {
        this.setState({currentSelectionImg: test})
    } 
    render() {
        const options = {
            loop:true,
            autoplay:true,
            autoplayTimeout:30000,
            margin:30,
            nav:true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            items:1,
            dots: false,
        };
        const descriptionData = productsDescription.filter(data => data.id === this.state.productId)[0];
        return (
            <>
                <Header />
                <div className="page-content">
                    <Banner title="Creating places that enhance the human experience." pagename="Product detail" bgimage={bnrimg.default} productList="Product List"/>
                    <div className="section-full p-tb20 bg-gray square_shape2 tm-about2-wrap">
                        <div className="container">
                            <div className="section-content ">
                                <div className="row">
                                    <div className="col-lg-5 col-md-6">
                                        <div className="m-about m-l50 m-r50">
                                            <img src={this.state.currentSelectionImg} alt="" />
                                        </div>
                                    </div>  
                                    <div className="col-lg-6 col-md-6 ">
                                        <div className="m-about-containt text-uppercase text-black p-t10">
                                            <span className="font-30 font-weight-400">{this.state.productTitle.replace(/%20/g, " ")}</span>
                                            <div className="m-30 p-30" style={{paddingTop: '5px'}}>
                                                {this.state.imagesList.length > 0 && this.state.imagesList.map((childImg) => {
                                                    return (
                                                        <> 
                                                            {childImg && 
                                                                <img 
                                                                    src={childImg.default}
                                                                    onClick={() => this.handleChange(childImg.default)}
                                                                    alt=""
                                                                    style={{
                                                                        width: '70px',
                                                                        height: '70px',
                                                                        margin: '10px',
                                                                        cursor: 'pointer',
                                                                        border: this.state.currentSelectionImg === childImg.default && '4px solid black'
                                                                    }}
                                                                    
                                                                />
                                                            }
                                                        </>
                                                    )
                                                })}
                                            </div>
                                            <p className='paragraph-text'>{descriptionData?.description1}</p>
                                            <p className='paragraph-text'>{descriptionData?.description2}</p>                                            
                                        </div>    
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
                <Footer3 />
            </>
        );
    };
};

export default ProductDetail;