import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../Common/Header';
import Footer from '../Common/Footer3';
import Banner from '../Segments/Banner';
import { projects } from '../JsonData/projectsData';

const filters = [
    { label: "SBM BANK", filter: ".sbm" },
    { label: "EMAMI", filter: ".emami" },
    { label: "EMAMI-MUMBAI", filter: ".emamiMumbai" },
    { label: "EMAMI AGROTECH-CHENNAI", filter: ".emamiAgrotechChennai" },
    { label: "BEIMO-ANDHERI", filter: ".beimoAndheri" },
    { label: "CARNIVAL", filter: ".carnival" },
    { label: "CARNIVAL 9TH FLOOR", filter: ".carnivalNinthFloor" },
    { label: "CARNIVAL RECEPTION", filter: ".carnivalReception" },
    { label: "ECO STAR GOREGAON", filter: ".ecoStarGoregaon" },
    { label: "GLORY OFFICE-TURBE", filter: ".gloryOfficeTurbe" },
    { label: "JAIPUR DISPLAY SHOWROOM", filter: ".jaipurDisplayShowroom" },
    { label: "JEWLLERS SHOWROOM", filter: ".jewllersShowroom" },
    { label: "UNITED TECHNOLOGIES - PUNE", filter: ".pune" },
    { label: "CHAKAN - PUNE", filter: ".puneSiteTwo" },
    { label: "RSB", filter: ".rsb" },
    { label: "REBALE", filter: ".Rebale" },
];

var bnrimg = require('./../../images/main-slider/slider1/006_result.webp');

class Projectslist extends React.Component {
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Banner title="Exploring our clients goals, priorities, lifestyle, and tastes" pagename="Our Projects" bgimage={bnrimg.default} />

                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb90 tm-work-wrap">
                        <div className="container" style={{width: '100%', marginLeft: '50px'}}>
                            {/* PAGINATION START */}
                            <div className="filter-wrap p-b50">
                                <ul className="masonry-filter link-style  text-uppercase">
                                    <li className="active"><NavLink to={"#"} data-filter="*" data-hover="All">All</NavLink></li>
                                    {filters.map((item, index) => (
                                        <li key={index}><NavLink to={"#"} data-filter={item.filter} data-hover={item.label}>{item.label}</NavLink></li>
                                    ))}
                                </ul>
                            </div>
                            {/* PAGINATION END */}
                        </div>
                        {/* GALLERY CONTENT START */}
                        <div className="portfolio-wrap mfp-gallery news-grid clearfix">
                            <div className="container-fluid">
                                <div className="row">
                                    {projects.map((item, index) => {
                                        const imgName = item.image?.default?.split('/')[3];
                                        return (
                                            <div key={index} className={`${item.filter} masonry-item col-lg-3 col-md-6 col-sm-6 m-b30`}>
                                                <div className="wt-img-effect ">
                                                    <img src={item.image.default} alt="" style={{height: '300px'}}/>
                                                    <div className="overlay-bx-2 ">
                                                        <div className="line-amiation  p-a40">
                                                            <div className="wt-post-info text-white">
                                                                <div className="wt-post-title ">
                                                                    <h2 className="post-title">
                                                                        <NavLink 
                                                                            to={{
                                                                                pathname:`/project-detail`,
                                                                                search: `search=${imgName}&folder=${item.folderName}`,
                                                                            }} 
                                                                            className="text-white font-18 letter-spacing-4 font-weight-600">
                                                                            {item.title}
                                                                        </NavLink></h2>
                                                                </div>
                                                                <div className="wt-post-text">
                                                                    <p>{item.description}</p>
                                                                </div>
                                                                <NavLink  
                                                                    to={{
                                                                        pathname:`/project-detail`,
                                                                        search: `search=${imgName}&folder=${item.folderName}`,
                                                                    }}
                                                                    className="v-button letter-spacing-4 font-12 text-uppercase p-l20"
                                                                >
                                                                    Read More
                                                                </NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        {/* GALLERY CONTENT END */}
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>

                <Footer />
            </>
        );
    };
};

export default Projectslist;