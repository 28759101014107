import React from 'react';
import { NavLink } from 'react-router-dom';

class Footer3 extends React.Component {
    constructor(props) {
        super(props);
        this.currentstate = { logo: require('./../../images/logo-dark.png').default };
    }

    updateFooterLogo = (updatedlogo) => { this.currentstate.logo = updatedlogo.default; }

    render() {

        return (
            <>
                <footer id="connectWithUs" className="site-footer footer-large footer-light footer-wide">
                    {/* FOOTER BLOCKES START */}
                    <div className="footer-top overlay-wraper">
                        <div className="overlay-main" />
                        <div className="container">
                            <div className="row">
                                {/* ABOUT COMPANY */}
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="widget widget_about">
                                        {/*<h4 class="widget-title">About Company</h4>*/}
                                        <div className="logo-footer clearfix p-b15" style={{"margin-top": "-15px"}}>
                                            <NavLink to={"/"}><img src={require("./../../images/Hariom.png").default} alt="" width={300} height={80} /></NavLink>
                                            
                                        </div>
                                        <div className="logo-footer clearfix p-b15"style={{"margin-top": "15px"}}>
                                            
                                            <h6 className="post-title-1"><b>We are also listed in 50 Beautiful House of India for our Beautiful Interior Projects.</b></h6>
                                            <img src={require("./../../images/footer.jpg").default} alt="" style={{height: "50%", width: "80%"}} />
                                        </div>
                                    </div>
                                </div>
                                {/* RESENT POST */}
                               
                                {/* USEFUL LINKS */}
                                <div className="col-lg-2 col-md-6 col-sm-6">
                                    <div className="widget widget_services inline-links">
                                        <h4 className="widget-title">Useful links</h4>
                                        <ul>
                                            <li><NavLink to={"/"}>Home</NavLink></li><br></br>
                                            <li><NavLink to={"/aboutus"}>About Us</NavLink></li><br></br>
                                            <li><NavLink to={"/our-products"}>Products</NavLink></li><br></br>
                                            <li><NavLink to={"/our-projects"}>Projects</NavLink></li><br></br>
                                        </ul>
                                    </div>
                                </div>
                                {/* TAGS */}
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="widget recent-posts-entry">
                                        <h4 className="widget-title  text-uppercase">Connect With Us</h4>
                                        <div className="section-content">
                                            <div className="widget-post-bx">
                                                <div className="widget-post clearfix">
                                                    <div className="wt-post-media" style={{"width": "40px" }}>
                                                        <span style={{fontSize: "20px"}}><a href="javascript:void(0)" className="fa fa-industry" /></span>
                                                    </div>
                                                    <div className="wt-post-info" style={{"margin-left": "50px" }}>
                                                        <div className="wt-post-header">
                                                            <h6 className="post-title-1">G-1, Dattatraya Tower, Sant Gyaneshwar Maharaj Road, Near Nancy S.T. Depot, Borivali(E), Mumbai-68. Tel.:022 2897 9608</h6>
                                                        </div>                                                      
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="widget-post-bx">
                                                <div className="widget-post clearfix">
                                                    <div className="wt-post-media" style={{"width": "40px" }}>
                                                        <span style={{fontSize: "20px"}}><a href="javascript:void(0)" className="fa fa-phone" /></span>
                                                    </div>
                                                    <div className="wt-post-info" style={{"margin-left": "50px" }}>
                                                        <div className="wt-post-header">
                                                            <h6 className="post-title-1">9967446360 / 9819016493</h6>
                                                        </div>                                                      
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="widget-post-bx">
                                                <div className="widget-post clearfix">
                                                    <div className="wt-post-media" style={{"width": "40px" }}>
                                                        <span style={{fontSize: "14px"}}><a href="javascript:void(0)" className="fa fa-envelope" /></span>
                                                    </div>
                                                    <div className="wt-post-info" style={{"margin-left": "50px" }}>
                                                        <div className="wt-post-header">
                                                            <h6 className="post-title-1">hariom_modular@yahoo.com</h6>
                                                        </div>                                                      
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* NEWSLETTER */}
                            </div>
                        </div>
                    </div>
                    {/* FOOTER COPYRIGHT */}
                    <div className="footer-bottom overlay-wraper">
                        <div className="overlay-main" />
                        <div className="container">
                            <div className="row">
                                <div className="wt-footer-bot-right">
                                <span className="copyrights-text">© 2023 Designed By <a href={"https://arvtechsolutions.com/"} target={"_blank"}><span style={{color: "orange"}}>ARVTech Solutions</span></a></span>
                                </div>
                                {/* <div className="wt-footer-bot-right">
                                    <ul className="copyrights-nav pull-right">
                                        <li><NavLink to={"/aboutus"}>Terms  &amp; Condition</NavLink></li>
                                        <li><NavLink to={"/aboutus"}>Privacy Policy</NavLink></li>
                                        <li><NavLink to={"/contactus"}>Contact Us</NavLink></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </footer>


            </>
        );
    };
};

export default Footer3;