import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const images = [
    // require('./../../images/gallery/036_result.webp'),
    require('./../../images/gallery/045_result.webp'),
    require('./../../images/projects/emami/10.webp'),
    require('./../../images/gallery/022_result.webp'),

]

class About extends React.Component {

    render() {
        const options = {
            loop: true,
            margin: 0,
            autoplay: true,
            autoplayTimeout: 3000,
            //center: true,
            nav: false,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1
                },
                480: {
                    items: 1
                },
                767: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }
        };
        return (
            <>
                <div className="section-full p-t50 bg-gray tm-welcome-warp">
                    <div className="container">
                        <div className="section-content">
                            <div className="row">
                                <div className="col-md-5 col-sm-12 text-uppercase text-black">
                                    <span className="font-30 font-weight-300">Welcome</span>
                                    <h2 className="font-40">
                                        We are <span className={this.props.colorclass1}>creative</span> <span className={this.props.colorclass2}>Building Design</span>  Company
                                    </h2>
                                    <p className='paragraph-text'>Hariom group was founded by <b>Mr. Chandrakant Vishwakarma</b> in 1990 about 33 years ago, by
venturing into home furniture during its inception and later pioneered into manufacturing office
modular furniture in India.</p>
                                    <p className='paragraph-text'>We cater to both residential as well as commercial clients while also undertaking turnkey projects
since 1990 A.D. Our expertise includes wide array of dedicated services for interior of Residence,
Commercial, Corporate, Hospitality (Such as: Hospitals, hotel and restaurants) & Institutional
spaces.
                                    </p>
                                    <NavLink to={"/aboutus"} className="btn-half site-button button-lg m-b15" style={{"background-color": "red"}}><span>Read More</span><em /></NavLink>
                                </div>
                                <div className="col-md-7 col-sm-12">
                                    <div className="m-carousel-1 m-l100">
                                        <OwlCarousel className="owl-carousel home-carousel-1 owl-btn-vertical-center" {...options}>
                                            {images.map((item, index) => (
                                                <div className="item" key={index}>
                                                    <div className="ow-img wt-img-effect zoom-slow">
                                                        <img src={item.default} alt="" />
                                                    </div>
                                                </div>

                                            ))}

                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                            <div className="hilite-title p-lr20 m-tb10 text-right text-uppercase bdr-gray bdr-right">
                                <strong>33 Year</strong>
                                <span className="text-black">Experience Working</span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default About;