export const projects = [
    {
        image: require('./../../images/projects/emami/1.webp'),
        title: 'emami',
        description: '',
        filter: "emami",
        folderName: 'emami',
        folderName: 'emami'
    },
    {
        image: require('./../../images/projects/emami/2.webp'),
        title: 'emami',
        description: '',
        filter: "emami",
        folderName: 'emami'
    },
    {
        image: require('./../../images/projects/emami/3.webp'),
        title: 'emami',
        description: '',
        filter: "emami",
        folderName: 'emami'
    },
    {
        image: require('./../../images/projects/emami/4.webp'),
        title: 'emami',
        description: '',
        filter: "emami",
        folderName: 'emami'
    },
    {
        image: require('./../../images/projects/emami/5.webp'),
        title: 'emami',
        description: '',
        filter: "emami",
        folderName: 'emami'
    },
    {
        image: require('./../../images/projects/emami/6.webp'),
        title: 'emami',
        description: '',
        filter: "emami",
        folderName: 'emami'
    },
    {
        image: require('./../../images/projects/emami/7.webp'),
        title: 'emami',
        description: '',
        filter: "emami",
        folderName: 'emami'
    },
    {
        image: require('./../../images/projects/emami/8.webp'),
        title: 'emami',
        description: '',
        filter: "emami",
        folderName: 'emami'
    },
    {
        image: require('./../../images/projects/emami/9.webp'),
        title: 'emami',
        description: '',
        filter: "emami",
        folderName: 'emami'
    },    
    {
        image: require('./../../images/projects/emami/10.webp'),
        title: 'emami',
        description: '',
        filter: "emami",
        folderName: 'emami'
    },
    {
        image: require('./../../images/projects/emami/11.webp'),
        title: 'emami',
        description: '',
        filter: "emami",
        folderName: 'emami'
    },
    {
        image: require('./../../images/projects/emami/12.webp'),
        title: 'emami',
        description: '',
        filter: "emami",
        folderName: 'emami'
    },
    {
        image: require('./../../images/projects/emami/13.webp'),
        title: 'emami',
        description: '',
        filter: "emami",
        folderName: 'emami'
    },
    {
        image: require('./../../images/projects/emami/14.webp'),
        title: 'emami',
        description: '',
        filter: "emami",
        folderName: 'emami'
    },
    {
        image: require('./../../images/projects/EMAMI AGROTECH-CHENNAI/1.webp'),
        title: 'EMAMI AGROTECH-CHENNAI',
        description: '',
        filter: "emamiAgrotechChennai",
        folderName: 'EMAMI AGROTECH-CHENNAI'
    },
    {
        image: require('./../../images/projects/EMAMI AGROTECH-CHENNAI/2.webp'),
        title: 'EMAMI AGROTECH-CHENNAI',
        description: '',
        filter: "emamiAgrotechChennai",
        folderName: 'EMAMI AGROTECH-CHENNAI'
    },
    {
        image: require('./../../images/projects/EMAMI AGROTECH-CHENNAI/3.webp'),
        title: 'EMAMI AGROTECH-CHENNAI',
        description: '',
        filter: "emamiAgrotechChennai",
        folderName: 'EMAMI AGROTECH-CHENNAI'
    },
    {
        image: require('./../../images/projects/EMAMI AGROTECH-CHENNAI/4.webp'),
        title: 'EMAMI AGROTECH-CHENNAI',
        description: '',
        filter: "emamiAgrotechChennai",
        folderName: 'EMAMI AGROTECH-CHENNAI'
    },
    {
        image: require('./../../images/projects/EMAMI AGROTECH-CHENNAI/5.webp'),
        title: 'EMAMI AGROTECH-CHENNAI',
        description: '',
        filter: "emamiAgrotechChennai",
        folderName: 'EMAMI AGROTECH-CHENNAI'
    },
    {
        image: require('./../../images/projects/EMAMI AGROTECH-CHENNAI/6.webp'),
        title: 'EMAMI AGROTECH-CHENNAI',
        description: '',
        filter: "emamiAgrotechChennai",
        folderName: 'EMAMI AGROTECH-CHENNAI'
    },
    {
        image: require('./../../images/projects/EMAMI AGROTECH-CHENNAI/7.webp'),
        title: 'EMAMI AGROTECH-CHENNAI',
        description: '',
        filter: "emamiAgrotechChennai",
        folderName: 'EMAMI AGROTECH-CHENNAI'
    },
    {
        image: require('./../../images/projects/EMAMI AGROTECH-CHENNAI/8.webp'),
        title: 'EMAMI AGROTECH-CHENNAI',
        description: '',
        filter: "emamiAgrotechChennai",
        folderName: 'EMAMI AGROTECH-CHENNAI'
    },
    {
        image: require('./../../images/projects/EMAMI AGROTECH-CHENNAI/9.webp'),
        title: 'EMAMI AGROTECH-CHENNAI',
        description: '',
        filter: "emamiAgrotechChennai",
        folderName: 'EMAMI AGROTECH-CHENNAI'
    },
    {
        image: require('./../../images/projects/EMAMI AGROTECH-CHENNAI/10.webp'),
        title: 'EMAMI AGROTECH-CHENNAI',
        description: '',
        filter: "emamiAgrotechChennai",
        folderName: 'EMAMI AGROTECH-CHENNAI'
    },
    {
        image: require('./../../images/projects/EMAMI-MUMBAI/1.webp'),
        title: 'EMAMI-MUMBAI',
        description: '',
        filter: "emamiMumbai",
        folderName: 'EMAMI-MUMBAI'
    },
    {
        image: require('./../../images/projects/EMAMI-MUMBAI/2.webp'),
        title: 'EMAMI-MUMBAI',
        description: '',
        filter: "emamiMumbai",
        folderName: 'EMAMI-MUMBAI'
    },
    {
        image: require('./../../images/projects/EMAMI-MUMBAI/3.webp'),
        title: 'EMAMI-MUMBAI',
        description: '',
        filter: "emamiMumbai",
        folderName: 'EMAMI-MUMBAI'
    },
    {
        image: require('./../../images/projects/EMAMI-MUMBAI/4.webp'),
        title: 'EMAMI-MUMBAI',
        description: '',
        filter: "emamiMumbai",
        folderName: 'EMAMI-MUMBAI'
    },
    {
        image: require('./../../images/projects/EMAMI-MUMBAI/5.webp'),
        title: 'EMAMI-MUMBAI',
        description: '',
        filter: "emamiMumbai",
        folderName: 'EMAMI-MUMBAI'
    },
    {
        image: require('./../../images/projects/EMAMI-MUMBAI/6.webp'),
        title: 'EMAMI-MUMBAI',
        description: '',
        filter: "emamiMumbai",
        folderName: 'EMAMI-MUMBAI'
    },
    {
        image: require('./../../images/projects/EMAMI-MUMBAI/7.webp'),
        title: 'EMAMI-MUMBAI',
        description: '',
        filter: "emamiMumbai",
        folderName: 'EMAMI-MUMBAI'
    },
    {
        image: require('./../../images/projects/EMAMI-MUMBAI/8.webp'),
        title: 'EMAMI-MUMBAI',
        description: '',
        filter: "emamiMumbai",
        folderName: 'EMAMI-MUMBAI'
    },
    {
        image: require('./../../images/projects/EMAMI-MUMBAI/9.webp'),
        title: 'EMAMI-MUMBAI',
        description: '',
        filter: "emamiMumbai",
        folderName: 'EMAMI-MUMBAI'
    },
    {
        image: require('./../../images/projects/EMAMI-MUMBAI/10.webp'),
        title: 'EMAMI-MUMBAI',
        description: '',
        filter: "emamiMumbai",
        folderName: 'EMAMI-MUMBAI'
    },
    {
        image: require('./../../images/projects/EMAMI-MUMBAI/11.webp'),
        title: 'EMAMI-MUMBAI',
        description: '',
        filter: "emamiMumbai",
        folderName: 'EMAMI-MUMBAI'
    },
    {
        image: require('./../../images/projects/EMAMI-MUMBAI/12.webp'),
        title: 'EMAMI-MUMBAI',
        description: '',
        filter: "emamiMumbai",
        folderName: 'EMAMI-MUMBAI'
    },
    {
        image: require('./../../images/projects/EMAMI-MUMBAI/13.webp'),
        title: 'EMAMI-MUMBAI',
        description: '',
        filter: "emamiMumbai",
        folderName: 'EMAMI-MUMBAI'
    },
    {
        image: require('./../../images/projects/EMAMI-MUMBAI/14.webp'),
        title: 'EMAMI-MUMBAI',
        description: '',
        filter: "emamiMumbai",
        folderName: 'EMAMI-MUMBAI'
    },
    {
        image: require('./../../images/projects/EMAMI-MUMBAI/15.webp'),
        title: 'EMAMI-MUMBAI',
        description: '',
        filter: "emamiMumbai",
        folderName: 'EMAMI-MUMBAI'
    },
    {
        image: require('./../../images/projects/EMAMI-MUMBAI/16.webp'),
        title: 'EMAMI-MUMBAI',
        description: '',
        filter: "emamiMumbai",
        folderName: 'EMAMI-MUMBAI'
    },
    {
        image: require('./../../images/projects/EMAMI-MUMBAI/17.webp'),
        title: 'EMAMI-MUMBAI',
        description: '',
        filter: "emamiMumbai",
        folderName: 'EMAMI-MUMBAI'
    },
    {
        image: require('./../../images/projects/EMAMI-MUMBAI/18.webp'),
        title: 'EMAMI-MUMBAI',
        description: '',
        filter: "emamiMumbai",
        folderName: 'EMAMI-MUMBAI'
    },
    {
        image: require('./../../images/projects/EMAMI-MUMBAI/19.webp'),
        title: 'EMAMI-MUMBAI',
        description: '',
        filter: "emamiMumbai",
        folderName: 'EMAMI-MUMBAI'
    },
    {
        image: require('./../../images/projects/EMAMI-MUMBAI/20.webp'),
        title: 'EMAMI-MUMBAI',
        description: '',
        filter: "emamiMumbai",
        folderName: 'EMAMI-MUMBAI'
    },
    {
        image: require('./../../images/projects/BEIMO-ANDHERI/1.webp'),
        title: 'BEIMO-ANDHERI',
        description: '',
        filter: "beimoAndheri",
        folderName: 'BEIMO-ANDHERI'
    },
    {
        image: require('./../../images/projects/BEIMO-ANDHERI/2.webp'),
        title: 'BEIMO-ANDHERI',
        description: '',
        filter: "beimoAndheri",
        folderName: 'BEIMO-ANDHERI'
    },
    {
        image: require('./../../images/projects/BEIMO-ANDHERI/3.webp'),
        title: 'BEIMO-ANDHERI',
        description: '',
        filter: "beimoAndheri",
        folderName: 'BEIMO-ANDHERI'
    },
    {
        image: require('./../../images/projects/BEIMO-ANDHERI/4.webp'),
        title: 'BEIMO-ANDHERI',
        description: '',
        filter: "beimoAndheri",
        folderName: 'BEIMO-ANDHERI'
    },
    {
        image: require('./../../images/projects/BEIMO-ANDHERI/5.webp'),
        title: 'BEIMO-ANDHERI',
        description: '',
        filter: "beimoAndheri",
        folderName: 'BEIMO-ANDHERI'
    },
    {
        image: require('./../../images/projects/BEIMO-ANDHERI/6.webp'),
        title: 'BEIMO-ANDHERI',
        description: '',
        filter: "beimoAndheri",
        folderName: 'BEIMO-ANDHERI'
    },
    {
        image: require('./../../images/projects/BEIMO-ANDHERI/7.webp'),
        title: 'BEIMO-ANDHERI',
        description: '',
        filter: "beimoAndheri",
        folderName: 'BEIMO-ANDHERI'
    },
    {
        image: require('./../../images/projects/BEIMO-ANDHERI/8.webp'),
        title: 'BEIMO-ANDHERI',
        description: '',
        filter: "beimoAndheri",
        folderName: 'BEIMO-ANDHERI'
    },
    {
        image: require('./../../images/projects/BEIMO-ANDHERI/9.webp'),
        title: 'BEIMO-ANDHERI',
        description: '',
        filter: "beimoAndheri",
        folderName: 'BEIMO-ANDHERI'
    },
    {
        image: require('./../../images/projects/BEIMO-ANDHERI/10.webp'),
        title: 'BEIMO-ANDHERI',
        description: '',
        filter: "beimoAndheri",
        folderName: 'BEIMO-ANDHERI'
    },
    {
        image: require('./../../images/projects/BEIMO-ANDHERI/11.webp'),
        title: 'BEIMO-ANDHERI',
        description: '',
        filter: "beimoAndheri",
        folderName: 'BEIMO-ANDHERI'
    },
    {
        image: require('./../../images/projects/BEIMO-ANDHERI/12.webp'),
        title: 'BEIMO-ANDHERI',
        description: '',
        filter: "beimoAndheri",
        folderName: 'BEIMO-ANDHERI'
    },
    {
        image: require('./../../images/projects/CARNIVAL/1.webp'),
        title: 'CARNIVAL',
        description: '',
        filter: "carnival",
        folderName: 'CARNIVAL'
    },
    {
        image: require('./../../images/projects/CARNIVAL/2.webp'),
        title: 'CARNIVAL',
        description: '',
        filter: "carnival",
        folderName: 'CARNIVAL'
    },
    {
        image: require('./../../images/projects/CARNIVAL/3.webp'),
        title: 'CARNIVAL',
        description: '',
        filter: "carnival",
        folderName: 'CARNIVAL'
    },
    {
        image: require('./../../images/projects/CARNIVAL/4.webp'),
        title: 'CARNIVAL',
        description: '',
        filter: "carnival",
        folderName: 'CARNIVAL'
    },
    {
        image: require('./../../images/projects/CARNIVAL/5.webp'),
        title: 'CARNIVAL',
        description: '',
        filter: "carnival",
        folderName: 'CARNIVAL'
    },
    {
        image: require('./../../images/projects/CARNIVAL/6.webp'),
        title: 'CARNIVAL',
        description: '',
        filter: "carnival",
        folderName: 'CARNIVAL'
    },
    {
        image: require('./../../images/projects/CARNIVAL/7.webp'),
        title: 'CARNIVAL',
        description: '',
        filter: "carnival",
        folderName: 'CARNIVAL'
    },
    {
        image: require('./../../images/projects/CARNIVAL/8.webp'),
        title: 'CARNIVAL',
        description: '',
        filter: "carnival",
        folderName: 'CARNIVAL'
    },
    {
        image: require('./../../images/projects/CARNIVAL/9.webp'),
        title: 'CARNIVAL',
        description: '',
        filter: "carnival",
        folderName: 'CARNIVAL'
    },
    {
        image: require('./../../images/projects/CARNIVAL/10.webp'),
        title: 'CARNIVAL',
        description: '',
        filter: "carnival",
        folderName: 'CARNIVAL'
    },
    {
        image: require('./../../images/projects/CARNIVAL/11.webp'),
        title: 'CARNIVAL',
        description: '',
        filter: "carnival",
        folderName: 'CARNIVAL'
    },
    {
        image: require('./../../images/projects/CARNIVAL/12.webp'),
        title: 'CARNIVAL',
        description: '',
        filter: "carnival",
        folderName: 'CARNIVAL'
    },
    {
        image: require('./../../images/projects/CARNIVAL 9TH FLOOR/1.webp'),
        title: 'CARNIVAL 9TH FLOOR',
        description: '',
        filter: "carnivalNinthFloor",
        folderName: 'CARNIVAL 9TH FLOOR'
    },
    {
        image: require('./../../images/projects/CARNIVAL 9TH FLOOR/2.webp'),
        title: 'CARNIVAL 9TH FLOOR',
        description: '',
        filter: "carnivalNinthFloor",
        folderName: 'CARNIVAL 9TH FLOOR'
    },
    {
        image: require('./../../images/projects/CARNIVAL 9TH FLOOR/3.webp'),
        title: 'CARNIVAL 9TH FLOOR',
        description: '',
        filter: "carnivalNinthFloor",
        folderName: 'CARNIVAL 9TH FLOOR'
    },
    {
        image: require('./../../images/projects/CARNIVAL 9TH FLOOR/4.webp'),
        title: 'CARNIVAL 9TH FLOOR',
        description: '',
        filter: "carnivalNinthFloor",
        folderName: 'CARNIVAL 9TH FLOOR'
    },
    {
        image: require('./../../images/projects/CARNIVAL 9TH FLOOR/5.webp'),
        title: 'CARNIVAL 9TH FLOOR',
        description: '',
        filter: "carnivalNinthFloor",
        folderName: 'CARNIVAL 9TH FLOOR'
    },
    {
        image: require('./../../images/projects/CARNIVAL 9TH FLOOR/6.webp'),
        title: 'CARNIVAL 9TH FLOOR',
        description: '',
        filter: "carnivalNinthFloor",
        folderName: 'CARNIVAL 9TH FLOOR'
    },
    {
        image: require('./../../images/projects/CARNIVAL 9TH FLOOR/7.webp'),
        title: 'CARNIVAL 9TH FLOOR',
        description: '',
        filter: "carnivalNinthFloor",
        folderName: 'CARNIVAL 9TH FLOOR'
    },
    {
        image: require('./../../images/projects/CARNIVAL 9TH FLOOR/8.webp'),
        title: 'CARNIVAL 9TH FLOOR',
        description: '',
        filter: "carnivalNinthFloor",
        folderName: 'CARNIVAL 9TH FLOOR'
    },
    {
        image: require('./../../images/projects/CARNIVAL 9TH FLOOR/9.webp'),
        title: 'CARNIVAL 9TH FLOOR',
        description: '',
        filter: "carnivalNinthFloor",
        folderName: 'CARNIVAL 9TH FLOOR'
    },
    {
        image: require('./../../images/projects/CARNIVAL 9TH FLOOR/10.webp'),
        title: 'CARNIVAL 9TH FLOOR',
        description: '',
        filter: "carnivalNinthFloor",
        folderName: 'CARNIVAL 9TH FLOOR'
    },
    {
        image: require('./../../images/projects/CARNIVAL RECEPTION/1.jpg'),
        title: 'CARNIVAL RECEPTION',
        description: '',
        filter: "carnivalReception",
        folderName: 'CARNIVAL RECEPTION'
    },
    {
        image: require('./../../images/projects/CARNIVAL RECEPTION/2.jpg'),
        title: 'CARNIVAL RECEPTION',
        description: '',
        filter: "carnivalReception",
        folderName: 'CARNIVAL RECEPTION'
    },
    {
        image: require('./../../images/projects/CARNIVAL RECEPTION/3.jpg'),
        title: 'CARNIVAL RECEPTION',
        description: '',
        filter: "carnivalReception",
        folderName: 'CARNIVAL RECEPTION'
    },
    {
        image: require('./../../images/projects/CARNIVAL RECEPTION/4.jpg'),
        title: 'CARNIVAL RECEPTION',
        description: '',
        filter: "carnivalReception",
        folderName: 'CARNIVAL RECEPTION'
    },
    {
        image: require('./../../images/projects/CARNIVAL RECEPTION/5.jpg'),
        title: 'CARNIVAL RECEPTION',
        description: '',
        filter: "carnivalReception",
        folderName: 'CARNIVAL RECEPTION'
    },
    {
        image: require('./../../images/projects/CARNIVAL RECEPTION/6.jpg'),
        title: 'CARNIVAL RECEPTION',
        description: '',
        filter: "carnivalReception",
        folderName: 'CARNIVAL RECEPTION'
    },
    {
        image: require('./../../images/projects/CARNIVAL RECEPTION/7.jpg'),
        title: 'CARNIVAL RECEPTION',
        description: '',
        filter: "carnivalReception",
        folderName: 'CARNIVAL RECEPTION'
    },
    {
        image: require('./../../images/projects/CARNIVAL RECEPTION/8.jpg'),
        title: 'CARNIVAL RECEPTION',
        description: '',
        filter: "carnivalReception",
        folderName: 'CARNIVAL RECEPTION'
    },
    {
        image: require('./../../images/projects/CARNIVAL RECEPTION/9.jpg'),
        title: 'CARNIVAL RECEPTION',
        description: '',
        filter: "carnivalReception",
        folderName: 'CARNIVAL RECEPTION'
    },
    {
        image: require('./../../images/projects/ECO STAR GOREGAON/1.webp'),
        title: 'ECO STAR GOREGAON',
        description: '',
        filter: "ecoStarGoregaon",
        folderName: 'ECO STAR GOREGAON'
    },
    {
        image: require('./../../images/projects/ECO STAR GOREGAON/2.webp'),
        title: 'ECO STAR GOREGAON',
        description: '',
        filter: "ecoStarGoregaon",
        folderName: 'ECO STAR GOREGAON'
    },
    {
        image: require('./../../images/projects/ECO STAR GOREGAON/3.webp'),
        title: 'ECO STAR GOREGAON',
        description: '',
        filter: "ecoStarGoregaon",
        folderName: 'ECO STAR GOREGAON'
    },
    {
        image: require('./../../images/projects/ECO STAR GOREGAON/4.webp'),
        title: 'ECO STAR GOREGAON',
        description: '',
        filter: "ecoStarGoregaon",
        folderName: 'ECO STAR GOREGAON'
    },
    {
        image: require('./../../images/projects/ECO STAR GOREGAON/5.webp'),
        title: 'ECO STAR GOREGAON',
        description: '',
        filter: "ecoStarGoregaon",
        folderName: 'ECO STAR GOREGAON'
    },
    {
        image: require('./../../images/projects/ECO STAR GOREGAON/6.webp'),
        title: 'ECO STAR GOREGAON',
        description: '',
        filter: "ecoStarGoregaon",
        folderName: 'ECO STAR GOREGAON'
    },
    {
        image: require('./../../images/projects/ECO STAR GOREGAON/7.webp'),
        title: 'ECO STAR GOREGAON',
        description: '',
        filter: "ecoStarGoregaon",
        folderName: 'ECO STAR GOREGAON'
    },
    {
        image: require('./../../images/projects/ECO STAR GOREGAON/8.webp'),
        title: 'ECO STAR GOREGAON',
        description: '',
        filter: "ecoStarGoregaon",
        folderName: 'ECO STAR GOREGAON'
    },
    {
        image: require('./../../images/projects/GLORY OFFICE-TURBE/1.webp'),
        title: 'GLORY OFFICE-TURBE',
        description: '',
        filter: "gloryOfficeTurbe",
        folderName: 'GLORY OFFICE-TURBE'
    },
    {
        image: require('./../../images/projects/GLORY OFFICE-TURBE/2.webp'),
        title: 'GLORY OFFICE-TURBE',
        description: '',
        filter: "gloryOfficeTurbe",
        folderName: 'GLORY OFFICE-TURBE'
    },
    {
        image: require('./../../images/projects/GLORY OFFICE-TURBE/3.webp'),
        title: 'GLORY OFFICE-TURBE',
        description: '',
        filter: "gloryOfficeTurbe",
        folderName: 'GLORY OFFICE-TURBE'
    },
    {
        image: require('./../../images/projects/GLORY OFFICE-TURBE/4.webp'),
        title: 'GLORY OFFICE-TURBE',
        description: '',
        filter: "gloryOfficeTurbe",
        folderName: 'GLORY OFFICE-TURBE'
    },
    {
        image: require('./../../images/projects/GLORY OFFICE-TURBE/5.webp'),
        title: 'GLORY OFFICE-TURBE',
        description: '',
        filter: "gloryOfficeTurbe",
        folderName: 'GLORY OFFICE-TURBE'
    },
    {
        image: require('./../../images/projects/GLORY OFFICE-TURBE/6.webp'),
        title: 'GLORY OFFICE-TURBE',
        description: '',
        filter: "gloryOfficeTurbe",
        folderName: 'GLORY OFFICE-TURBE'
    },
    {
        image: require('./../../images/projects/GLORY OFFICE-TURBE/7.webp'),
        title: 'GLORY OFFICE-TURBE',
        description: '',
        filter: "gloryOfficeTurbe",
        folderName: 'GLORY OFFICE-TURBE'
    },
    {
        image: require('./../../images/projects/JAIPUR DISPLAY SHOWROOM/1.webp'),
        title: 'JAIPUR DISPLAY SHOWROOM',
        description: '',
        filter: "jaipurDisplayShowroom",
        folderName: 'JAIPUR DISPLAY SHOWROOM'
    },
    {
        image: require('./../../images/projects/JAIPUR DISPLAY SHOWROOM/2.webp'),
        title: 'JAIPUR DISPLAY SHOWROOM',
        description: '',
        filter: "jaipurDisplayShowroom",
        folderName: 'JAIPUR DISPLAY SHOWROOM'
    },
    {
        image: require('./../../images/projects/JAIPUR DISPLAY SHOWROOM/3.webp'),
        title: 'JAIPUR DISPLAY SHOWROOM',
        description: '',
        filter: "jaipurDisplayShowroom",
        folderName: 'JAIPUR DISPLAY SHOWROOM'
    },
    {
        image: require('./../../images/projects/JAIPUR DISPLAY SHOWROOM/4.webp'),
        title: 'JAIPUR DISPLAY SHOWROOM',
        description: '',
        filter: "jaipurDisplayShowroom",
        folderName: 'JAIPUR DISPLAY SHOWROOM'
    },
    {
        image: require('./../../images/projects/JAIPUR DISPLAY SHOWROOM/5.webp'),
        title: 'JAIPUR DISPLAY SHOWROOM',
        description: '',
        filter: "jaipurDisplayShowroom",
        folderName: 'JAIPUR DISPLAY SHOWROOM'
    },
    {
        image: require('./../../images/projects/JAIPUR DISPLAY SHOWROOM/6.webp'),
        title: 'JAIPUR DISPLAY SHOWROOM',
        description: '',
        filter: "jaipurDisplayShowroom",
        folderName: 'JAIPUR DISPLAY SHOWROOM'
    },
    {
        image: require('./../../images/projects/JAIPUR DISPLAY SHOWROOM/7.webp'),
        title: 'JAIPUR DISPLAY SHOWROOM',
        description: '',
        filter: "jaipurDisplayShowroom",
        folderName: 'JAIPUR DISPLAY SHOWROOM'
    },
    {
        image: require('./../../images/projects/JAIPUR DISPLAY SHOWROOM/8.webp'),
        title: 'JAIPUR DISPLAY SHOWROOM',
        description: '',
        filter: "jaipurDisplayShowroom",
        folderName: 'JAIPUR DISPLAY SHOWROOM'
    },
    {
        image: require('./../../images/projects/JAIPUR DISPLAY SHOWROOM/9.webp'),
        title: 'JAIPUR DISPLAY SHOWROOM',
        description: '',
        filter: "jaipurDisplayShowroom",
        folderName: 'JAIPUR DISPLAY SHOWROOM'
    },
    {
        image: require('./../../images/projects/JAIPUR DISPLAY SHOWROOM/10.webp'),
        title: 'JAIPUR DISPLAY SHOWROOM',
        description: '',
        filter: "jaipurDisplayShowroom",
        folderName: 'JAIPUR DISPLAY SHOWROOM'
    },
    {
        image: require('./../../images/projects/JAIPUR DISPLAY SHOWROOM/11.webp'),
        title: 'JAIPUR DISPLAY SHOWROOM',
        description: '',
        filter: "jaipurDisplayShowroom",
        folderName: 'JAIPUR DISPLAY SHOWROOM'
    },
    {
        image: require('./../../images/projects/JAIPUR DISPLAY SHOWROOM/12.webp'),
        title: 'JAIPUR DISPLAY SHOWROOM',
        description: '',
        filter: "jaipurDisplayShowroom",
        folderName: 'JAIPUR DISPLAY SHOWROOM'
    },
    {
        image: require('./../../images/projects/JEWLLERS SHOWROOM/1.webp'),
        title: 'JEWLLERS SHOWROOM',
        description: '',
        filter: "jewllersShowroom",
        folderName: 'JEWLLERS SHOWROOM'
    },
    {
        image: require('./../../images/projects/JEWLLERS SHOWROOM/2.webp'),
        title: 'JEWLLERS SHOWROOM',
        description: '',
        filter: "jewllersShowroom",
        folderName: 'JEWLLERS SHOWROOM'
    },
    {
        image: require('./../../images/projects/JEWLLERS SHOWROOM/3.webp'),
        title: 'JEWLLERS SHOWROOM',
        description: '',
        filter: "jewllersShowroom",
        folderName: 'JEWLLERS SHOWROOM'
    },
    {
        image: require('./../../images/projects/JEWLLERS SHOWROOM/4.webp'),
        title: 'JEWLLERS SHOWROOM',
        description: '',
        filter: "jewllersShowroom",
        folderName: 'JEWLLERS SHOWROOM'
    },
    {
        image: require('./../../images/projects/JEWLLERS SHOWROOM/5.webp'),
        title: 'JEWLLERS SHOWROOM',
        description: '',
        filter: "jewllersShowroom",
        folderName: 'JEWLLERS SHOWROOM'
    },
    {
        image: require('./../../images/projects/JEWLLERS SHOWROOM/6.webp'),
        title: 'JEWLLERS SHOWROOM',
        description: '',
        filter: "jewllersShowroom",
        folderName: 'JEWLLERS SHOWROOM'
    },
    {
        image: require('./../../images/projects/JEWLLERS SHOWROOM/7.webp'),
        title: 'JEWLLERS SHOWROOM',
        description: '',
        filter: "jewllersShowroom",
        folderName: 'JEWLLERS SHOWROOM'
    },
    {
        image: require('./../../images/projects/JEWLLERS SHOWROOM/8.webp'),
        title: 'JEWLLERS SHOWROOM',
        description: '',
        filter: "jewllersShowroom",
        folderName: 'JEWLLERS SHOWROOM'
    },
    {
        image: require('./../../images/projects/PUNE/1.webp'),
        title: 'PUNE',
        description: '',
        filter: "pune",
        folderName: 'PUNE'
    },
    {
        image: require('./../../images/projects/PUNE/2.webp'),
        title: 'PUNE',
        description: '',
        filter: "pune",
        folderName: 'PUNE'
    },
    {
        image: require('./../../images/projects/PUNE/3.webp'),
        title: 'PUNE',
        description: '',
        filter: "pune",
        folderName: 'PUNE'
    },
    {
        image: require('./../../images/projects/PUNE/4.webp'),
        title: 'PUNE',
        description: '',
        filter: "pune",
        folderName: 'PUNE'
    },
    {
        image: require('./../../images/projects/PUNE/5.webp'),
        title: 'PUNE',
        description: '',
        filter: "pune",
        folderName: 'PUNE'
    },
    {
        image: require('./../../images/projects/PUNE/6.webp'),
        title: 'PUNE',
        description: '',
        filter: "pune",
        folderName: 'PUNE'
    },
    {
        image: require('./../../images/projects/PUNE/7.webp'),
        title: 'PUNE',
        description: '',
        filter: "pune",
        folderName: 'PUNE'
    },
    {
        image: require('./../../images/projects/PUNE/8.webp'),
        title: 'PUNE',
        description: '',
        filter: "pune",
        folderName: 'PUNE'
    },
    {
        image: require('./../../images/projects/PUNE/9.webp'),
        title: 'PUNE',
        description: '',
        filter: "pune",
        folderName: 'PUNE'
    },
    {
        image: require('./../../images/projects/PUNE/10.webp'),
        title: 'PUNE',
        description: '',
        filter: "pune",
        folderName: 'PUNE'
    },
    {
        image: require('./../../images/projects/PUNE/11.webp'),
        title: 'PUNE',
        description: '',
        filter: "pune",
        folderName: 'PUNE'
    },
    {
        image: require('./../../images/projects/PUNE/12.webp'),
        title: 'PUNE',
        description: '',
        filter: "pune",
        folderName: 'PUNE'
    },
    {
        image: require('./../../images/projects/PUNE SITE 2/1.webp'),
        title: 'PUNE SITE 2',
        description: '',
        filter: "puneSiteTwo",
        folderName: 'PUNE SITE 2'
    },
    {
        image: require('./../../images/projects/PUNE SITE 2/2.webp'),
        title: 'PUNE SITE 2',
        description: '',
        filter: "puneSiteTwo",
        folderName: 'PUNE SITE 2'
    },
    {
        image: require('./../../images/projects/PUNE SITE 2/3.webp'),
        title: 'PUNE SITE 2',
        description: '',
        filter: "puneSiteTwo",
        folderName: 'PUNE SITE 2'
    },
    {
        image: require('./../../images/projects/PUNE SITE 2/4.webp'),
        title: 'PUNE SITE 2',
        description: '',
        filter: "puneSiteTwo",
        folderName: 'PUNE SITE 2'
    },
    {
        image: require('./../../images/projects/PUNE SITE 2/5.webp'),
        title: 'PUNE SITE 2',
        description: '',
        filter: "puneSiteTwo",
        folderName: 'PUNE SITE 2'
    },
    {
        image: require('./../../images/projects/PUNE SITE 2/6.webp'),
        title: 'PUNE SITE 2',
        description: '',
        filter: "puneSiteTwo",
        folderName: 'PUNE SITE 2'
    },
    {
        image: require('./../../images/projects/PUNE SITE 2/7.webp'),
        title: 'PUNE SITE 2',
        description: '',
        filter: "puneSiteTwo",
        folderName: 'PUNE SITE 2'
    },
    {
        image: require('./../../images/projects/PUNE SITE 2/8.webp'),
        title: 'PUNE SITE 2',
        description: '',
        filter: "puneSiteTwo",
        folderName: 'PUNE SITE 2'
    },
    {
        image: require('./../../images/projects/PUNE SITE 2/9.webp'),
        title: 'PUNE SITE 2',
        description: '',
        filter: "puneSiteTwo",
        folderName: 'PUNE SITE 2'
    },
    {
        image: require('./../../images/projects/PUNE SITE 2/10.webp'),
        title: 'PUNE SITE 2',
        description: '',
        filter: "puneSiteTwo",
        folderName: 'PUNE SITE 2'
    },
    {
        image: require('./../../images/projects/PUNE SITE 2/11.webp'),
        title: 'PUNE SITE 2',
        description: '',
        filter: "puneSiteTwo",
        folderName: 'PUNE SITE 2'
    },
    {
        image: require('./../../images/projects/PUNE SITE 2/12.webp'),
        title: 'PUNE SITE 2',
        description: '',
        filter: "puneSiteTwo",
        folderName: 'PUNE SITE 2'
    },
    {
        image: require('./../../images/projects/PUNE SITE 2/13.webp'),
        title: 'PUNE SITE 2',
        description: '',
        filter: "puneSiteTwo",
        folderName: 'PUNE SITE 2'
    },
    {
        image: require('./../../images/projects/PUNE SITE 2/14.webp'),
        title: 'PUNE SITE 2',
        description: '',
        filter: "puneSiteTwo",
        folderName: 'PUNE SITE 2'
    },
    {
        image: require('./../../images/projects/RSB/1.webp'),
        title: 'RSB',
        description: '',
        filter: "rsb",
        folderName: 'RSB'
    },
    {
        image: require('./../../images/projects/RSB/2.webp'),
        title: 'RSB',
        description: '',
        filter: "rsb",
        folderName: 'RSB'
    },
    {
        image: require('./../../images/projects/RSB/3.webp'),
        title: 'RSB',
        description: '',
        filter: "rsb",
        folderName: 'RSB'
    },
    {
        image: require('./../../images/projects/RSB/4.webp'),
        title: 'RSB',
        description: '',
        filter: "rsb",
        folderName: 'RSB'
    },
    {
        image: require('./../../images/projects/RSB/5.webp'),
        title: 'RSB',
        description: '',
        filter: "rsb",
        folderName: 'RSB'
    },
    {
        image: require('./../../images/projects/RSB/6.webp'),
        title: 'RSB',
        description: '',
        filter: "rsb",
        folderName: 'RSB'
    },
    {
        image: require('./../../images/projects/RSB/7.webp'),
        title: 'RSB',
        description: '',
        filter: "rsb",
        folderName: 'RSB'
    },
    {
        image: require('./../../images/projects/RSB/8.webp'),
        title: 'RSB',
        description: '',
        filter: "rsb",
        folderName: 'RSB'
    },
    {
        image: require('./../../images/projects/RSB/9.webp'),
        title: 'RSB',
        description: '',
        filter: "rsb",
        folderName: 'RSB'
    },
    {
        image: require('./../../images/projects/RSB/10.webp'),
        title: 'RSB',
        description: '',
        filter: "rsb",
        folderName: 'RSB'
    },
    {
        image: require('./../../images/projects/RSB/11.webp'),
        title: 'RSB',
        description: '',
        filter: "rsb",
        folderName: 'RSB'
    },
    {
        image: require('./../../images/projects/RSB/12.webp'),
        title: 'RSB',
        description: '',
        filter: "rsb",
        folderName: 'RSB'
    },
    {
        image: require('./../../images/projects/REBALE/1.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/2.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/3.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/4.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/5.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/6.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/7.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/8.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/9.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/10.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/11.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/12.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/13.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/14.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/15.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/16.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/17.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/18.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/19.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/20.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/21.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/22.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/23.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/24.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/25.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/26.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/27.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/28.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/29.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/30.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/31.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/32.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/33.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/REBALE/34.webp'),
        title: 'REBALE',
        description: '',
        filter: "Rebale",
        folderName: 'REBALE'
    },
    {
        image: require('./../../images/projects/sbm_bank/1.webp'),
        title: 'SBM BANK',
        description: '',
        filter: "sbm",
        folderName: 'sbm_bank'
    },
    {
        image: require('./../../images/projects/sbm_bank/2.webp'),
        title: 'SBM BANK',
        description: '',
        filter: "sbm",
        folderName: 'sbm_bank'
    },
    {
        image: require('./../../images/projects/sbm_bank/3.webp'),
        title: 'SBM BANK',
        description: '',
        filter: "sbm",
        folderName: 'sbm_bank'
    },
    {
        image: require('./../../images/projects/sbm_bank/4.webp'),
        title: 'SBM BANK',
        description: '',
        filter: "sbm",
        folderName: 'sbm_bank'
    },
    {
        image: require('./../../images/projects/sbm_bank/5.webp'),
        title: 'SBM BANK',
        description: '',
        filter: "sbm",
        folderName: 'sbm_bank'
    },
    {
        image: require('./../../images/projects/sbm_bank/6.webp'),
        title: 'SBM BANK',
        description: '',
        filter: "sbm",
        folderName: 'sbm_bank'
    },
    {
        image: require('./../../images/projects/sbm_bank/7.webp'),
        title: 'SBM BANK',
        description: '',
        filter: "sbm",
        folderName: 'sbm_bank'
    },
    {
        image: require('./../../images/projects/sbm_bank/8.webp'),
        title: 'SBM BANK',
        description: '',
        filter: "sbm",
        folderName: 'sbm_bank'
    },
    {
        image: require('./../../images/projects/sbm_bank/9.webp'),
        title: 'SBM BANK',
        description: '',
        filter: "sbm",
        folderName: 'sbm_bank'
    },
    {
        image: require('./../../images/projects/sbm_bank/10.webp'),
        title: 'SBM BANK',
        description: '',
        filter: "sbm",
        folderName: 'sbm_bank'
    },
    {
        image: require('./../../images/projects/sbm_bank/11.webp'),
        title: 'SBM BANK',
        description: '',
        filter: "sbm",
        folderName: 'sbm_bank'
    },
    {
        image: require('./../../images/projects/sbm_bank/12.webp'),
        title: 'SBM BANK',
        description: '',
        filter: "sbm",
        folderName: 'sbm_bank'
    },
    {
        image: require('./../../images/projects/sbm_bank/13.webp'),
        title: 'SBM BANK',
        description: '',
        filter: "sbm",
        folderName: 'sbm_bank'
    },
    {
        image: require('./../../images/projects/sbm_bank/14.webp'),
        title: 'SBM BANK',
        description: '',
        filter: "sbm",
        folderName: 'sbm_bank'
    },
    {
        image: require('./../../images/projects/sbm_bank/15.webp'),
        title: 'SBM BANK',
        description: '',
        filter: "sbm",
        folderName: 'sbm_bank'
    },
    {
        image: require('./../../images/projects/sbm_bank/16.webp'),
        title: 'SBM BANK',
        description: '',
        filter: "sbm",
        folderName: 'sbm_bank'
    }
]