export const products = [
    { 
        id: 'product_1',
        title: 'Linear Workstation',
        dataList: 
        [
            {
                image: require('./../../images/products/linear-workstation/1.webp'),
                title: 'Linear Workstation',
                description: 'All size available.',
                filter: 'linear-col',
                folderName: 'linear-workstation',
            },
            {
                image: require('./../../images/products/linear-workstation/2.webp'),
                title: 'Linear Workstation',
                description: 'All size available.',
                filter: 'linear-col',
                folderName: 'linear-workstation',
            },
            {
                image: require('./../../images/products/linear-workstation/3.webp'),
                title: 'Linear Workstation',
                description: 'All size available.',
                filter: 'linear-col',
                folderName: 'linear-workstation',
            },
            {
                image: require('./../../images/products/linear-workstation/4.webp'),
                title: 'Linear Workstation',
                description: 'All size available.',
                filter: 'linear-col',
                folderName: 'linear-workstation',
            },
            {
                image: require('./../../images/products/linear-workstation/5.webp'),
                title: 'Linear Workstation',
                description: 'All size available.',
                filter: 'linear-col',
                folderName: 'linear-workstation',
            },
            {
                image: require('./../../images/products/linear-workstation/6.webp'),
                title: 'Linear Workstation',
                description: 'All size available.',
                filter: 'linear-col',
                folderName: 'linear-workstation',
            },
            {
                image: require('./../../images/products/linear-workstation/7.webp'),
                title: 'Linear Workstation',
                description: 'All size available.',
                filter: 'linear-col',
                folderName: 'linear-workstation',
            },
            {
                image: require('./../../images/products/linear-workstation/8.webp'),
                title: 'Linear Workstation',
                description: 'All size available.',
                filter: 'linear-col',
                folderName: 'linear-workstation',
            },
            {
                image: require('./../../images/products/linear-workstation/9.webp'),
                title: 'Linear Workstation',
                description: 'All size available.',
                filter: 'linear-col',
                folderName: 'linear-workstation',
            },
            {
                image: require('./../../images/products/linear-workstation/10.webp'),
                title: 'Linear Workstation',
                description: 'All size available.',
                filter: 'linear-col',
                folderName: 'linear-workstation',
            },
            {
                image: require('./../../images/products/linear-workstation/11.webp'),
                title: 'Linear Workstation',
                description: 'All size available.',
                filter: 'linear-col',
                folderName: 'linear-workstation',
            },
        ]
    },
    { 
        id: 'product_2',
        title: 'Square MS Linear Workstation',
        dataList: 
        [
            {
                image: require('./../../images/products/square-ms-linear-workstation/1.webp'),
                title: 'Square MS Linear Workstation',
                description: 'All size available.',
                folderName: 'square-ms-linear-workstation',
            },
            {
                image: require('./../../images/products/square-ms-linear-workstation/2.webp'),
                title: 'Square MS Linear Workstation',
                description: 'All size available.',
                folderName: 'square-ms-linear-workstation',
            },
            {
                image: require('./../../images/products/square-ms-linear-workstation/3.webp'),
                title: 'Square MS Linear Workstation',
                description: 'All size available.',
                folderName: 'square-ms-linear-workstation',
            },
            {
                image: require('./../../images/products/square-ms-linear-workstation/4.webp'),
                title: 'Square MS Linear Workstation',
                description: 'All size available.',
                folderName: 'square-ms-linear-workstation',
            }
        ]
    },
    { 
        id: 'product_3',
        title: 'Triangle MS Linear Workstation',
        dataList: 
        [
            {
                image: require('./../../images/products/triangle-ms-linear-workstation/1.webp'),
                title: 'Triangle MS Linear Workstation',
                description: 'All size available.',
                folderName: 'triangle-ms-linear-workstation',
            },
            {
                image: require('./../../images/products/triangle-ms-linear-workstation/2.webp'),
                title: 'Triangle MS Linear Workstation',
                description: 'All size available.',
                folderName: 'triangle-ms-linear-workstation',
            },
            {
                image: require('./../../images/products/triangle-ms-linear-workstation/3.webp'),
                title: 'Triangle MS Linear Workstation',
                description: 'All size available.',
                folderName: 'triangle-ms-linear-workstation',
            },
            {
                image: require('./../../images/products/triangle-ms-linear-workstation/4.webp'),
                title: 'Triangle MS Linear Workstation',
                description: 'All size available.',
                folderName: 'triangle-ms-linear-workstation',
            },
            {
                image: require('./../../images/products/triangle-ms-linear-workstation/5.webp'),
                title: 'Triangle MS Linear Workstation',
                description: 'All size available.',
                folderName: 'triangle-ms-linear-workstation',
            },
            {
                image: require('./../../images/products/triangle-ms-linear-workstation/6.webp'),
                title: 'Triangle MS Linear Workstation',
                description: 'All size available.',
                folderName: 'triangle-ms-linear-workstation',
            },
            {
                image: require('./../../images/products/triangle-ms-linear-workstation/7.webp'),
                title: 'Triangle MS Linear Workstation',
                description: 'All size available.',
                folderName: 'triangle-ms-linear-workstation',
            },
           
        ]
    },
    { 
        id: 'product_4',
        title: 'Square MS Cubicle Workstation',
        dataList: 
        [
            {
                image: require('./../../images/products/square-ms-cubicle-workstation/1.webp'),
                title: 'Square MS Cubicle Workstation',
                description: 'All size available.',
                folderName: 'square-ms-cubicle-workstation',
            },
            {
                image: require('./../../images/products/square-ms-cubicle-workstation/2.webp'),
                title: 'Square MS Cubicle Workstation',
                description: 'All size available.',
                folderName: 'square-ms-cubicle-workstation',
            },
            {
                image: require('./../../images/products/square-ms-cubicle-workstation/3.webp'),
                title: 'Square MS Cubicle Workstation',
                description: 'All size available.',
                folderName: 'square-ms-cubicle-workstation',
            },
            {
                image: require('./../../images/products/square-ms-cubicle-workstation/4.webp'),
                title: 'Square MS Cubicle Workstation',
                description: 'All size available.',
                folderName: 'square-ms-cubicle-workstation',
            },
            {
                image: require('./../../images/products/square-ms-cubicle-workstation/5.webp'),
                title: 'Square MS Cubicle Workstation',
                description: 'All size available.',
                folderName: 'square-ms-cubicle-workstation',
            },
            {
                image: require('./../../images/products/CUBICLES/1.jpg'),
                title: 'Square MS Cubicle Workstation',
                description: 'All size available.',
                folderName: 'CUBICLES',
            },
            {
                image: require('./../../images/products/CUBICLES/2.jpg'),
                title: 'Square MS Cubicle Workstation',
                description: 'All size available.',
                folderName: 'CUBICLES',
            },
            {
                image: require('./../../images/products/CUBICLES/3.jpg'),
                title: 'Square MS Cubicle Workstation',
                description: 'All size available.',
                folderName: 'CUBICLES',
            },
            {
                image: require('./../../images/products/CUBICLES/4.jpg'),
                title: 'Square MS Cubicle Workstation',
                description: 'All size available.',
                folderName: 'CUBICLES',
            }
        ]
    },
    { 
        id: 'product_5',
        title: 'Triangle MS Cubicle Workstation',
        dataList: 
        [
            {
                image: require('./../../images/products/triangle-ms-cubicle-workstation/1.webp'),
                title: 'Triangle MS Cubicle Workstation',
                description: 'All size available.',
                folderName: 'triangle-ms-cubicle-workstation',
            },
            {
                image: require('./../../images/products/triangle-ms-cubicle-workstation/2.webp'),
                title: 'Triangle MS Cubicle Workstation',
                description: 'All size available.',
                folderName: 'triangle-ms-cubicle-workstation',
            },
            {
                image: require('./../../images/products/triangle-ms-cubicle-workstation/3.webp'),
                title: 'Triangle MS Cubicle Workstation',
                description: 'All size available.',
                folderName: 'triangle-ms-cubicle-workstation',
            },
            {
                image: require('./../../images/products/triangle-ms-cubicle-workstation/4.webp'),
                title: 'Triangle MS Cubicle Workstation',
                description: 'All size available.',
                folderName: 'triangle-ms-cubicle-workstation',
            },
            {
                image: require('./../../images/products/triangle-ms-cubicle-workstation/5.webp'),
                title: 'Triangle MS Cubicle Workstation',
                description: 'All size available.',
                folderName: 'triangle-ms-cubicle-workstation',
            }
        ]
    },
    { 
        id: 'product_6',
        title: 'Executive Square MS Pipe Workstation',
        dataList: 
        [
            {
                image: require('./../../images/products/executive-square-ms-pipe-workstation/1.webp'),
                title: 'Executive Square MS Pipe Workstation',
                description: 'All size available.',
                folderName: 'executive-square-ms-pipe-workstation',
            },
            {
                image: require('./../../images/products/executive-square-ms-pipe-workstation/2.webp'),
                title: 'Executive Square MS Pipe Workstation',
                description: 'All size available.',
                folderName: 'executive-square-ms-pipe-workstation',
            },
            {
                image: require('./../../images/products/executive-square-ms-pipe-workstation/3.webp'),
                title: 'Executive Square MS Pipe Workstation',
                description: 'All size available.',
                folderName: 'executive-square-ms-pipe-workstation',
            },
            {
                image: require('./../../images/products/executive-square-ms-pipe-workstation/4.webp'),
                title: 'Executive Square MS Pipe Workstation',
                description: 'All size available.',
                folderName: 'executive-square-ms-pipe-workstation',
            },
            {
                image: require('./../../images/products/executive-square-ms-pipe-workstation/5.webp'),
                title: 'Executive Square MS Pipe Workstation',
                description: 'All size available.',
                folderName: 'executive-square-ms-pipe-workstation',
            },
            {
                image: require('./../../images/products/executive-square-ms-pipe-workstation/6.webp'),
                title: 'Executive Square MS Pipe Workstation',
                description: 'All size available.',
                folderName: 'executive-square-ms-pipe-workstation',
            },
            {
                image: require('./../../images/products/executive-square-ms-pipe-workstation/7.webp'),
                title: 'Executive Square MS Pipe Workstation',
                description: 'All size available.',
                folderName: 'executive-square-ms-pipe-workstation',
            },
            {
                image: require('./../../images/products/executive-square-ms-pipe-workstation/8.webp'),
                title: 'Executive Square MS Pipe Workstation',
                description: 'All size available.',
                folderName: 'executive-square-ms-pipe-workstation',
            },
            {
                image: require('./../../images/products/executive-square-ms-pipe-workstation/9.webp'),
                title: 'Executive Square MS Pipe Workstation',
                description: 'All size available.',
                folderName: 'executive-square-ms-pipe-workstation',
            },
            {
                image: require('./../../images/products/executive-square-ms-pipe-workstation/10.webp'),
                title: 'Executive Square MS Pipe Workstation',
                description: 'All size available.',
                folderName: 'executive-square-ms-pipe-workstation',
            },
        ]
    },
    { 
        id: 'product_7',
        title: 'Executive Triangle MS Pipe Workstation',
        dataList: 
        [
            {
                image: require('./../../images/products/executive-tirangle-ms-pipe-workstation/1.webp'),
                title: 'Executive Triangle MS Pipe Workstation',
                description: 'All size available.',
                folderName: 'executive-triangle-ms-pipe-workstation',
            },
            {
                image: require('./../../images/products/executive-tirangle-ms-pipe-workstation/2.webp'),
                title: 'Executive Triangle MS Pipe Workstation',
                description: 'All size available.',
                folderName: 'executive-triangle-ms-pipe-workstation',
            },
            {
                image: require('./../../images/products/executive-tirangle-ms-pipe-workstation/3.webp'),
                title: 'Executive Triangle MS Pipe Workstation',
                description: 'All size available.',
                folderName: 'executive-triangle-ms-pipe-workstation',
            },
            {
                image: require('./../../images/products/executive-tirangle-ms-pipe-workstation/4.webp'),
                title: 'Executive Triangle MS Pipe Workstation',
                description: 'All size available.',
                folderName: 'executive-triangle-ms-pipe-workstation',
            },
            {
                image: require('./../../images/products/executive-tirangle-ms-pipe-workstation/5.webp'),
                title: 'Executive Triangle MS Pipe Workstation',
                description: 'All size available.',
                folderName: 'executive-triangle-ms-pipe-workstation',
            },
            {
                image: require('./../../images/products/executive-tirangle-ms-pipe-workstation/6.webp'),
                title: 'Executive Triangle MS Pipe Workstation',
                description: 'All size available.',
                folderName: 'executive-triangle-ms-pipe-workstation',
            },
        ]
    },
    { 
        id: 'product_8',
        title: 'Tapered Triangle Leg Workstation',
        dataList: 
        [
            {
                image: require('./../../images/products/tapered-triangle-leg-workstation/1.webp'),
                title: 'Tapered Triangle Leg Workstation',
                description: 'All size available.',
                folderName: 'tapered-triangle-leg-workstation',
            },
            {
                image: require('./../../images/products/tapered-triangle-leg-workstation/2.webp'),
                title: 'Tapered Triangle Leg Workstation',
                description: 'All size available.',
                folderName: 'tapered-triangle-leg-workstation',
            },
            {
                image: require('./../../images/products/tapered-triangle-leg-workstation/3.webp'),
                title: 'Tapered Triangle Leg Workstation',
                description: 'All size available.',
                folderName: 'tapered-triangle-leg-workstation',
            },
            {
                image: require('./../../images/products/tapered-triangle-leg-workstation/4.webp'),
                title: 'Tapered Triangle Leg Workstation',
                description: 'All size available.',
                folderName: 'tapered-triangle-leg-workstation',
            },
            {
                image: require('./../../images/products/tapered-triangle-leg-workstation/5.webp'),
                title: 'Tapered Triangle Leg Workstation',
                description: 'All size available.',
                folderName: 'tapered-triangle-leg-workstation',
            },
        ]
    },
    { 
        id: 'product_9',
        title: 'MD Desk',
        dataList: 
        [
            {
                image: require('./../../images/products/md-desk/1.webp'),
                title: 'MD Desk',
                description: 'All size available.',
                folderName: 'md-desk',
            },
            {
                image: require('./../../images/products/md-desk/2.webp'),
                title: 'MD Desk',
                description: 'All size available.',
                folderName: 'md-desk',
            },
            {
                image: require('./../../images/products/md-desk/3.webp'),
                title: 'MD Desk',
                description: 'All size available.',
                folderName: 'md-desk',
            },
            {
                image: require('./../../images/products/md-desk/4.webp'),
                title: 'MD Desk',
                description: 'All size available.',
                folderName: 'md-desk',
            },
            {
                image: require('./../../images/products/md-desk/5.webp'),
                title: 'MD Desk',
                description: 'All size available.',
                folderName: 'md-desk',
            },
            { 
                image: require('./../../images/products/md-desk/6.webp'),
                title: 'MD Desk',
                description: 'All size available.',
                folderName: 'md-desk',
            },
            {
                image: require('./../../images/products/md-desk/7.webp'),
                title: 'MD Desk',
                description: 'All size available.',
                folderName: 'md-desk',
            },
            {
                image: require('./../../images/products/md-desk/8.webp'),
                title: 'MD Desk',
                description: 'All size available.',
                folderName: 'md-desk',
            },
            {
                image: require('./../../images/products/md-desk/9.webp'),
                title: 'MD Desk',
                description: 'All size available.',
                folderName: 'md-desk',
            },
            {
                image: require('./../../images/products/md-desk/10.webp'),
                title: 'MD Desk',
                description: 'All size available.',
                folderName: 'md-desk',
            },
            {
                image: require('./../../images/products/md-desk/11.webp'),
                title: 'MD Desk',
                description: 'All size available.',
                folderName: 'md-desk',
            },
            {
                image: require('./../../images/products/md-desk/12.webp'),
                title: 'MD Desk',
                description: 'All size available.',
                folderName: 'md-desk',
            },
            {
                image: require('./../../images/products/md-desk/13.webp'),
                title: 'MD Desk',
                description: 'All size available.',
                folderName: 'md-desk',
            },
            {
                image: require('./../../images/products/md-desk/14.webp'),
                title: 'MD Desk',
                description: 'All size available.',
                folderName: 'md-desk',
            },
            {
                image: require('./../../images/products/md-desk/15.webp'),
                title: 'MD Desk',
                description: 'All size available.',
                folderName: 'md-desk',
            },
        ]
    },
    { 
        id: 'product_10',
        title: 'Executive Desk',
        dataList: 
        [
            {
                image: require('./../../images/products/executive-desk-1/1.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-1',
            },
            {
                image: require('./../../images/products/executive-desk-1/2.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-1',
            },
            {
                image: require('./../../images/products/executive-desk-1/3.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-1',
            },
            {
                image: require('./../../images/products/executive-desk-1/4.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-1',
            },
            {
                image: require('./../../images/products/executive-desk-1/5.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-1',
            },
            {
                image: require('./../../images/products/executive-desk-1/6.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-1',
            },
            {
                image: require('./../../images/products/executive-desk-1/7.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-1',
            },
            {
                image: require('./../../images/products/executive-desk-1/8.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-1',
            },
            {
                image: require('./../../images/products/executive-desk-1/9.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-1',
            },
            {
                image: require('./../../images/products/executive-desk-2/1.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-2',
            },
            {
                image: require('./../../images/products/executive-desk-2/2.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-2',
            },
            {
                image: require('./../../images/products/executive-desk-2/3.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-2',
            },
            {
                image: require('./../../images/products/executive-desk-2/4.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-2',
            },
            {
                image: require('./../../images/products/executive-desk-3/1.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-3',
            },
            {
                image: require('./../../images/products/executive-desk-3/2.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-3',
            },
            {
                image: require('./../../images/products/executive-desk-3/3.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-3',
            },
            {
                image: require('./../../images/products/executive-desk-3/4.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-3',
            },
            {
                image: require('./../../images/products/executive-desk-4/1.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-4',
            },
            {
                image: require('./../../images/products/executive-desk-4/2.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-4',
            },
            {
                image: require('./../../images/products/executive-desk-4/3.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-4',
            },
            {
                image: require('./../../images/products/executive-desk-4/4.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-4',
            },
            {
                image: require('./../../images/products/executive-desk-4/5.webp'),
                title: 'Executive Desk',
                description: 'All size available.',
                folderName: 'executive-desk-4',
            }
        ]
    },
    { 
        id: 'product_11',
        title: 'Conference Table',
        dataList: 
        [
            {
                image: require('./../../images/products/Conference Table-1/1.jpg'),
                title: 'Conference Table',
                description: 'All size available.',
                folderName: 'Conference Table-1',
            },
            {
                image: require('./../../images/products/Conference Table-1/2.jpg'),
                title: 'Conference Table',
                description: 'All size available.',
                folderName: 'Conference Table-1',
            },
            {
                image: require('./../../images/products/Conference Table-1/3.jpg'),
                title: 'Conference Table',
                description: 'All size available.',
                folderName: 'Conference Table-1',
            },
            {
                image: require('./../../images/products/Conference Table-1/4.jpg'),
                title: 'Conference Table',
                description: 'All size available.',
                folderName: 'Conference Table-1',
            },
            {
                image: require('./../../images/products/Conference Table-1/5.jpg'),
                title: 'Conference Table',
                description: 'All size available.',
                folderName: 'Conference Table-1',
            },
            {
                image: require('./../../images/products/Conference Table-2/1.jpg'),
                title: 'Conference Table',
                description: 'All size available.',
                folderName: 'Conference Table-2',
            },
            {
                image: require('./../../images/products/Conference Table-2/2.jpg'),
                title: 'Conference Table',
                description: 'All size available.',
                folderName: 'Conference Table-2',
            },
            {
                image: require('./../../images/products/Conference Table-2/3.jpg'),
                title: 'Conference Table',
                description: 'All size available.',
                folderName: 'Conference Table-2',
            },
            {
                image: require('./../../images/products/Conference Table-2/4.jpg'),
                title: 'Conference Table',
                description: 'All size available.',
                folderName: 'Conference Table-2',
            },
            {
                image: require('./../../images/products/Conference Table-2/5.jpg'),
                title: 'Conference Table',
                description: 'All size available.',
                folderName: 'Conference Table-2',
            },
            {
                image: require('./../../images/products/Conference Table-3/1.jpg'),
                title: 'Conference Table',
                description: 'All size available.',
                folderName: 'Conference Table-3',
            },
            {
                image: require('./../../images/products/Conference Table-3/2.jpg'),
                title: 'Conference Table',
                description: 'All size available.',
                folderName: 'Conference Table-3',
            },
            {
                image: require('./../../images/products/Conference Table-3/3.jpg'),
                title: 'Conference Table',
                description: 'All size available.',
                folderName: 'Conference Table-3',
            },
            {
                image: require('./../../images/products/Conference Table-3/4.jpg'),
                title: 'Conference Table',
                description: 'All size available.',
                folderName: 'Conference Table-3',
            }
        ]
    },
    { 
        id: 'product_12',
        title: 'MS Leg Option',
        dataList: 
        [
            {
                image: require('./../../images/products/ms-leg-option/1.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/2.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/3.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/4.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/5.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/6.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/7.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/8.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/9.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/10.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/11.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/12.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/13.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/14.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/15.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/16.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/17.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/18.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/19.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/20.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/21.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/22.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/23.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/24.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/25.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            },
            {
                image: require('./../../images/products/ms-leg-option/26.webp'),
                title: 'MS Leg Option',
                description: 'All size available.',
                folderName: 'ms-leg-option',
            }
        ]
    },
    {
        id: 'product_13',
        title: 'Workstation',
        dataList: 
        [
            {        
                image: require('./../../images/products/WORKSTATION/1.jpg'),
                title: 'Workstation',
                description: 'All size available.',
                filter: 'workstation-col',
                folderName: 'WORKSTATION',
            },
            {
                image: require('./../../images/products/WORKSTATION/1.jpg'),
                title: 'Workstation',
                description: 'All size available.',
                filter: 'workstation-col',
                folderName: 'WORKSTATION',
            },
            {
                image: require('./../../images/products/WORKSTATION/2.jpg'),
                title: 'Workstation',
                description: 'All size available.',
                filter: 'workstation-col',
                folderName: 'WORKSTATION',
            },
            {
                image: require('./../../images/products/WORKSTATION/3.jpg'),
                title: 'Workstation',
                description: 'All size available.',
                filter: 'workstation-col',
                folderName: 'WORKSTATION',
            },
            {
                image: require('./../../images/products/WORKSTATION/4.jpg'),
                title: 'Workstation',
                description: 'All size available.',
                filter: 'workstation-col',
                folderName: 'WORKSTATION',
            },
        ]
    },
    {
        id: 'product_14',
        title: '30MM Partition',
        dataList: 
        [
            {        
                image: require('./../../images/products/30MM/1.jpg'),
                title: '30MM Partition',
                description: 'All size available.',
                folderName: '30MM',
            },
            {        
                image: require('./../../images/products/30MM/2.jpg'),
                title: '30MM Partition',
                description: 'All size available.',
                folderName: '30MM',
            },
            {        
                image: require('./../../images/products/30MM/3.jpg'),
                title: '30MM Partition',
                description: 'All size available.',
                folderName: '30MM',
            },
            {        
                image: require('./../../images/products/30MM/4.jpg'),
                title: '30MM Partition',
                description: 'All size available.',
                folderName: '30MM',
            },
        ]
    },
    {
        id: 'product_15',
        title: 'Modular Sofa',
        dataList: 
        [
            { 
                image: require('./../../images/products/Modular Sofa/1.jpg'),
                title: 'Modular Sofa',
                description: 'All size available.',
                filter: 'modular-col',
                folderName: 'Modular Sofa',
            },
            {
                image: require('./../../images/products/Modular Sofa/2.jpg'),
                title: 'Modular Sofa',
                description: 'All size available.',
                filter: 'modular-col',
                folderName: 'Modular Sofa',
            },
            {
                image: require('./../../images/products/Modular Sofa/3.jpg'),
                title: 'Modular Sofa',
                description: 'All size available.',
                filter: 'modular-col',
                folderName: 'Modular Sofa',
            },
            {
                image: require('./../../images/products/Modular Sofa/4.jpg'),
                title: 'Modular Sofa',
                description: 'All size available.',
                filter: 'modular-col',
                folderName: 'Modular Sofa',
            },
        ]
    },
    {
        id: 'product_16',
        title: 'Reception Table',
        dataList: 
        [
            {
                image: require('./../../images/products/RECEPTION TABLE-1/1.jpg'),
                title: 'Reception Table',
                description: 'All size available.',
                filter: 'reception-col',
                folderName: 'RECEPTION TABLE-1',
            },
            {
                image: require('./../../images/products/RECEPTION TABLE-1/2.jpg'),
                title: 'Reception Table',
                description: 'All size available.',
                filter: 'reception-col',
                folderName: 'RECEPTION TABLE-1',
            },
            {
                image: require('./../../images/products/RECEPTION TABLE-1/3.jpg'),
                title: 'Reception Table',
                description: 'All size available.',
                filter: 'reception-col',
                folderName: 'RECEPTION TABLE-1',
            },
            {
                image: require('./../../images/products/RECEPTION TABLE-1/4.jpg'),
                title: 'Reception Table',
                description: 'All size available.',
                filter: 'reception-col',
                folderName: 'RECEPTION TABLE-1',
            },
            {
                image: require('./../../images/products/RECEPTION TABLE-1/5.jpg'),
                title: 'Reception Table',
                description: 'All size available.',
                filter: 'reception-col',
                folderName: 'RECEPTION TABLE-1',
            },
        ],
    },
    {
        id: 'product_17',
        title: 'Straight Desk',
        dataList: 
        [
            {
                image: require('./../../images/products/STRAIGHT DESK-1/1.jpg'),
                title: 'Straight Desk',
                description: 'All size available.',
                filter: 'straight-col',
                folderName: 'STRAIGHT DESK-1',
            },
            {
                image: require('./../../images/products/STRAIGHT DESK-1/2.jpg'),
                title: 'Straight Desk',
                description: 'All size available.',
                filter: 'straight-col',
                folderName: 'STRAIGHT DESK-1',
            },
            {
                image: require('./../../images/products/STRAIGHT DESK-1/3.jpg'),
                title: 'Straight Desk',
                description: 'All size available.',
                filter: 'straight-col',
                folderName: 'STRAIGHT DESK-1',
            },
            {
                image: require('./../../images/products/STRAIGHT DESK-1/4.jpg'),
                title: 'Straight Desk',
                description: 'All size available.',
                filter: 'straight-col',
                folderName: 'STRAIGHT DESK-1',
            },
            {
                image: require('./../../images/products/STRAIGHT DESK-1/5.jpg'),
                title: 'Straight Desk',
                description: 'All size available.',
                filter: 'straight-col',
                folderName: 'STRAIGHT DESK-1',
            },
            {
                image: require('./../../images/products/STRAIGHT DESK-2/1.jpg'),
                title: 'Straight Desk',
                description: 'All size available.',
                filter: 'straight-col',
                folderName: 'STRAIGHT DESK-2',
            },
            {
                image: require('./../../images/products/STRAIGHT DESK-2/2.jpg'),
                title: 'Straight Desk',
                description: 'All size available.',
                filter: 'straight-col',
                folderName: 'STRAIGHT DESK-2',
            },
            {
                image: require('./../../images/products/STRAIGHT DESK-2/3.jpg'),
                title: 'Straight Desk',
                description: 'All size available.',
                filter: 'straight-col',
                folderName: 'STRAIGHT DESK-2',
            },
            {
                image: require('./../../images/products/STRAIGHT DESK-2/4.jpg'),
                title: 'Straight Desk',
                description: 'All size available.',
                filter: 'straight-col',
                folderName: 'STRAIGHT DESK-2',
            },
        ],
    },
    {
        id: 'product_18',
        title: '60MM Partition',
        dataList: 
        [
            {        
                image: require('./../../images/products/60MM/1.jpg'),
                title: '60MM Partition',
                description: 'All size available.',
                folderName: '60MM',
            },
            {        
                image: require('./../../images/products/60MM/2.jpg'),
                title: '60MM Partition',
                description: 'All size available.',
                folderName: '60MM',
            },
            {        
                image: require('./../../images/products/60MM/3.jpg'),
                title: '60MM Partition',
                description: 'All size available.',
                folderName: '60MM',
            },
            {        
                image: require('./../../images/products/60MM/4.jpg'),
                title: '60MM Partition',
                description: 'All size available.',
                folderName: '60MM',
            },
        ]
    },
]

export const productsDescription = [
    {
        id: 'product_1',
        description1: `Linear Workstation | SBOF Staging Linear is a modern workstation range combining a refined leg
        and beam system with cable management facilities adaptable to today's ever evolving needs.`,
        description2: `Linear Wood is a very detailed texture, but one that has the control and order of a natural "tight-grained" wood.
        The accuracy of its compact lines when mixed with a subtle-tonality enhances the quality of the surface finish.
        Linear wood meets the demand for interior design to have an understated luxury.`,
    },
    {
        id: 'product_2',
        description1: `Square MS Linear Workstation | SBOF Staging Linear is a modern workstation range combining a refined leg
        and beam system with cable management facilities adaptable to today's ever evolving needs.`,
        description2: `Linear Wood is a very detailed texture, but one that has the control and order of a natural "tight-grained" wood.
        The accuracy of its compact lines when mixed with a subtle-tonality enhances the quality of the surface finish.
        Linear wood meets the demand for interior design to have an understated luxury.`,
    },
    {
        id: 'product_3',
        description1: `Triangle MS Linear Workstation | SBOF Staging Linear is a modern workstation range combining a refined leg
        and beam system with cable management facilities adaptable to today's ever evolving needs.`,
        description2: `Linear Wood is a very detailed texture, but one that has the control and order of a natural "tight-grained" wood.
        The accuracy of its compact lines when mixed with a subtle-tonality enhances the quality of the surface finish.
        Linear wood meets the demand for interior design to have an understated luxury.`,
    },
    {
        id: 'product_4',
        description1: `Cubicles in the 2010s and 2020s are usually equipped with a computer, monitor, keyboard and mouse on the work surface.
         Cubicles typically have a desk phone.`,
        description2: `A cubicle is a partially enclosed office workspace that is separated from neighboring workspaces
         by partitions that are usually 5–6 feet (1.5–1.8 m) tall. Its purpose is to isolate office workers and managers
          from the sights and noises of an open workspace so that they may concentrate with fewer distractions.`,
    },
    {
        id: 'product_5',
        description1: `Cubicles in the 2010s and 2020s are usually equipped with a computer, monitor, keyboard and mouse on the work surface.
         Cubicles typically have a desk phone.`,
        description2: `A cubicle is a partially enclosed office workspace that is separated from neighboring workspaces
         by partitions that are usually 5–6 feet (1.5–1.8 m) tall. Its purpose is to isolate office workers and managers
          from the sights and noises of an open workspace so that they may concentrate with fewer distractions.`,
    },
    {
        id: 'product_6',
        description1: `Executive Square MS Pipe Workstation | SBOF Staging Linear is a modern workstation range combining a refined leg
        and beam system with cable management facilities adaptable to today's ever evolving needs.`,
        description2: `Linear Wood is a very detailed texture, but one that has the control and order of a natural "tight-grained" wood.
        The accuracy of its compact lines when mixed with a subtle-tonality enhances the quality of the surface finish.
        Linear wood meets the demand for interior design to have an understated luxury.`,
    },
    {
        id: 'product_7',
        description1: `Executive Triangle MS Pipe Workstation | SBOF Staging Linear is a modern workstation range combining a refined leg
        and beam system with cable management facilities adaptable to today's ever evolving needs.`,
        description2: `Linear Wood is a very detailed texture, but one that has the control and order of a natural "tight-grained" wood.
        The accuracy of its compact lines when mixed with a subtle-tonality enhances the quality of the surface finish.
        Linear wood meets the demand for interior design to have an understated luxury.`,
    },
    {
        id: 'product_8',
        description1: `Tapered Triangle Leg Workstation | SBOF Staging Linear is a modern workstation range combining a refined leg
        and beam system with cable management facilities adaptable to today's ever evolving needs.`,
        description2: `Linear Wood is a very detailed texture, but one that has the control and order of a natural "tight-grained" wood.
        The accuracy of its compact lines when mixed with a subtle-tonality enhances the quality of the surface finish.
        Linear wood meets the demand for interior design to have an understated luxury.`,
    },
    {
        id: 'product_9',
        description1: `furniture, household equipment, usually made of wood, metal, plastics, marble, glass, fabrics, or related 
        materials and having a variety of different purposes.`,
        description2: `Furniture consists of large objects such as tables, chairs, or beds that are used in a room for sitting or 
        lying on or for putting things on or in. Each piece of furniture in their home suited the style of the house.`,
    },
    {
        id: 'product_10',
        description1: `Quarter sawn: the end grain is oriented 60-90 degrees to the face of the board.`,
        description2: `There are generally 3 types of wood cuts: Plain sawn: the board's end grain is parallel or ranges from parallel to 
        the face of the board to a 30 degree angle. Rift sawn: the end grain is oriented 30-60 degrees to the face of the board.`,
    },
    {
        id: 'product_11',
        description1: `Conference table in American English. a large table, often rectangular, around which a number of people 
        may be seated, as when holding a conference.`,
        description2: `A conference table is a long and wide table used for meetings and gatherings, typically in a workplace. 
        Conference tables have a larger and wider table top than a standard dining table, and provides ample room for paper and important 
        materials for convenience and efficiency.`,
    },
    {
        id: 'product_12',
        description1: `Quarter sawn: the end grain is oriented 60-90 degrees to the face of the board.`,
        description2: `There are generally 3 types of wood cuts: Plain sawn: the board's end grain is parallel or ranges from parallel to 
        the face of the board to a 30 degree angle. Rift sawn: the end grain is oriented 30-60 degrees to the face of the board.`,
    },
    {
        id: 'product_13',
        description1: `Workstations are computers that are specifically configured to meet the most demanding technical computing requirements.
         To be considered a workstation, systems must include key capabilities related to performance, reliability, data integrity, scalability, and expandability.`,
        description2: `Workstations typically consist of a working surface (such as a desk or table), a seat or chair,
         and the equipment and tools necessary for individual job responsibilities, often including a computer. 
         The design and setup of office workstations may vary depending on employee roles, company culture, and other needs.
        `,
    },
    {
        id: 'product_14',
        description1: `Quarter sawn: the end grain is oriented 60-90 degrees to the face of the board.`,
        description2: `There are generally 3 types of wood cuts: Plain sawn: the board's end grain is parallel or ranges from parallel to 
        the face of the board to a 30 degree angle. Rift sawn: the end grain is oriented 30-60 degrees to the face of the board.`,
    },
    {
        id: 'product_15',
        description1: `Modular sofas are made up of portable sections called modules. This gives you the versatility to arrange your sofa in a range of different
         configurations to best suit your lifestyle and the layout of your space.`,
        description2: `Modular sofas are more expensive than your average sofa because they are made to be versatile, and come in sections that (commonly) have
         separate price tags. This is because you can quite literally build your own modular sofa.`,
    },
    {
        id: 'product_16',
        description1: `Reception area furniture, also called waiting room furnishings, consists of reception desks, guest chairs, 
        accent tables, décor and much more.`,
        description2: `Reception desks are more than just a piece of furniture in an office or a hotel lobby. 
        They serve as the first point of contact for visitors, clients, and guests, playing a crucial role in 
        creating a lasting impression and ensuring the smooth functioning of any establishment.`,
    },
    {
        id: 'product_17',
        description1: `An straight desk is a piece of furniture that is specifically designed for use in an office setting. 
        It is typically used as a workspace for an individual, and is often equipped with features such as drawers, compartments, 
        and other storage solutions to help keep the workspace organized and efficient.`,
        description2: `The type of desk you choose can have a significant impact on your productivity and overall well-being.
         One popular option is the straight desk, which is a simple, straight-lined desk that can be used in a variety of settings.`,
    },
    {
        id: 'product_18',
        description1: `furniture, household equipment, usually made of wood, metal, plastics, marble, glass, fabrics, or related 
        materials and having a variety of different purposes.`,
        description2: `Furniture consists of large objects such as tables, chairs, or beds that are used in a room for sitting or 
        lying on or for putting things on or in. Each piece of furniture in their home suited the style of the house.`,
    },
    {
        id: 'product_45',
        description1: `furniture, household equipment, usually made of wood, metal, plastics, marble, glass, fabrics, or related 
        materials and having a variety of different purposes.`,
        description2: `Furniture consists of large objects such as tables, chairs, or beds that are used in a room for sitting or 
        lying on or for putting things on or in. Each piece of furniture in their home suited the style of the house.`,
    },
]