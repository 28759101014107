import React from 'react';
import Header from '../Common/Header';
import Footer3 from '../Common/Footer3';
import Banner from '../Segments/Banner';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
var bnrimg = require('./../../images/banner/022_result.webp');

const childImgSelection = {
    border: '3px solid black'
}

class ProjectDetail extends React.Component {
    constructor(props) {
        super(props);
        const imgName = this.props?.location?.search?.split('&')[0]?.split('=')[1]??'notFound';
        const currentImgSrc = `/static/media/${imgName}`;
        const folderName = this.props?.location?.search?.split('&')[1]?.split('=')[1]?.replace("%20", " ")??null;
        let imagesList = [];
        if(folderName !== null){
            const images = require.context('./../../images/projects/', true);
            imagesList = images.keys().map(image => {
                    const folderMatch = image?.split('/')[1];
                    return folderMatch === folderName && images(image)
                }
            );
        }
        // Set initial state
        this.state = {
            currentSelectionImg: currentImgSrc,
            imagesList: imagesList,
            folderName: folderName
        };
        // Binding this keyword
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(test) {
        this.setState({currentSelectionImg: test})
    } 
    render() {
        const options = {
            loop:true,
            autoplay:true,
            autoplayTimeout:30000,
            margin:30,
            nav:true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            items:1,
            dots: false,
        };
        return (
            <>
                <Header />
                <div className="page-content">
                    <Banner title="Creating places that enhance the human experience." pagename="Project detail" bgimage={bnrimg.default} projectList="Projects List"/>
                    <div className="section-full p-tb70 bg-gray square_shape2 tm-about2-wrap">
                        <div className="container">
                            <div className="section-content ">
                                <div className="row">
                                    <div className="col-lg-5 col-md-6">
                                        <div className="m-about m-l50 m-r50">
                                            <img src={this.state.currentSelectionImg} alt="" />
                                        </div>
                                    </div>  
                                    <div className="col-lg-6 col-md-6 ">
                                        <span className="font-30 font-weight-400 text-uppercase">{this.state.folderName}</span>
                                        <div className="m-30 p-30" style={{paddingTop: '5px'}}>
                                            {this.state.imagesList.length > 0 && this.state.imagesList.map((childImg) => {
                                                return (
                                                    <> 
                                                        {childImg && 
                                                            <img 
                                                                src={childImg.default}
                                                                onClick={() => this.handleChange(childImg.default)}
                                                                alt=""
                                                                style={{
                                                                    width: '70px',
                                                                    height: '70px',
                                                                    margin: '10px',
                                                                    cursor: 'pointer',
                                                                    border: this.state.currentSelectionImg === childImg.default && '4px solid black'
                                                                }}
                                                                
                                                            />
                                                        }
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
                <Footer3 />
            </>
        );
    };
};

export default ProjectDetail;