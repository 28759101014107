import React from 'react';
import { NavLink } from 'react-router-dom';
import CountUp from 'react-countup';

var bgimg = require('./../../images/background/014_result.webp');
var img1 = require('./../../images/steps/1.png');
var img2 = require('./../../images/steps/2.png');
var img3 = require('./../../images/steps/3.png');
var img4 = require('./../../images/steps/4.png');
var img5 = require('./../../images/steps/5.png');

class Statistics extends React.Component {
    
    render() {
        
        return (
            <>
                <div className="section-full p-t70 p-b30 overlay-wraper bg-top-center bg-parallax tm-facts-wrap" data-stellar-background-ratio="0.5" style={{ backgroundImage: "url(" + bgimg.default + ")" }}>
                        <div className="overlay-main opacity-08 bg-black" />
                        <div className="container ">
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="some-facts">
                                        <div className="text-white">
                                            <h2 className="font-50 text-uppercase">
                                                How It Works ?
                                            </h2>
                                            <p className="font-18 font-weight-300">We work with a team of talented partners, brands, and professionals who pour all their knowledge into everything we build. We only collaborate with premium suppliers and manufacturers of the most exquisite marble, wood, and kitchen fittings.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="row" style={{padding: "30px 0px"}}>
                                        <div className="col-md-4 col-sm-4 col-xs-4">
                                            <div className="wt-icon-box-wraper p-a10 text-white m-b30">
                                                <div className="icon-content text-center">
                                                    <div className="font-40 font-weight-600 m-b5"><img src={img1.default} alt="" width="90"/></div>
                                                    <div className="wt-separator-outer m-b10"><div className="wt-separator bg-white" /></div>
                                                    <span className="text-uppercase">Meet a Designer</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-4">
                                            <div className="wt-icon-box-wraper p-a10 text-white m-b30">
                                                <div className="icon-content text-center">
                                                <div className="font-40 font-weight-600 m-b5"><img src={img2.default} alt=""  width="50"/></div>
                                                    <div className="wt-separator-outer m-b10"><div className="wt-separator bg-white" /></div>
                                                    <span className="text-uppercase">Finalised Layout & Design</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-4">
                                            <div className="wt-icon-box-wraper p-a10 text-white m-b30">
                                                <div className="icon-content text-center">
                                                <div className="font-40 font-weight-600 m-b5"><img src={img3.default} alt=""  width="50"/></div>
                                                    <div className="wt-separator-outer m-b10"><div className="wt-separator bg-white" /></div>
                                                    <span className="text-uppercase">Execution Begins</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-sm-4 col-xs-4">
                                            <div className="wt-icon-box-wraper p-a10 text-white m-b30">
                                                <div className="icon-content text-center">
                                                <div className="font-40 font-weight-600 m-b5"><img src={img4.default} alt=""  width="50"/></div>
                                                    <div className="wt-separator-outer m-b10"><div className="wt-separator bg-white" /></div>
                                                    <span className="text-uppercase">Final Installations</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-4">
                                            <div className="wt-icon-box-wraper p-a10 text-white m-b30">
                                                <div className="icon-content text-center">
                                                    <div className="font-40 font-weight-600 m-b5"><img src={img5.default} alt=""  width="50"/></div>
                                                    <div className="wt-separator-outer m-b10"><div className="wt-separator bg-white" /></div>
                                                    <span className="text-uppercase">Move in and Enjoy</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </>
        );
    }
};

export default Statistics;